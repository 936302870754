<template>
  <div class="homepage-overflow-x-hidden white" v-if="landingPageContent">
    <div v-html="getPageContent()"></div>
  </div>
</template>
<script>
import { addPictureTagInHtmlCode } from "@/services/util.service";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "LandingComponent",
  components: {},
   data: () => ({
    variants: [1366,768,360],
    mediaImageUrl
    
  }),
  computed: {
    isMobileDevice: function () {
      return this.$vuetify.display.xsOnly ? true : false;
    },
    landingPageContent: function () {
      return this.$store.state.layout.pageContent;
    },
    pageId: function () {
      return this.$route.meta.pid;
    },
  },
 
  methods: {
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PATH_URL;
    },
    getPageContent() {
      if (this.landingPageContent.length) {
        let data = this.landingPageContent.filter((element) => {
          return element.id === this.pageId;
        })[0];
        
        if (data) {
          let path = data.content;
          var div = document.createElement("div");
          div.innerHTML = path;
          let imgSrcUrls = div.getElementsByTagName("img");
          let count=imgSrcUrls.length;
          if(data.identifier=="crane-wedding"){

            for (let i = 0; i < count; i++) {         
          let urlValue = imgSrcUrls[i].getAttribute("src");
          if (urlValue) {
            imgSrcUrls[i].setAttribute(
              "src",
              this.imageBasePathUrl() +
                "/media/" +
                imgSrcUrls[i].src
                  .replace(window.location.href, "")
                  .replace(window.location.origin, "")
                  .replace(/%22%7D%7D/g, "")
                  .replace(/%7B%7B/g, "")
                  .replace(/%20url=%22/g, "")
                  .replace("media", "")
                  
            );
          }

        }

          }
          else{
            for (let i = 0; i < count; i++) {
          imgSrcUrls[i].parentElement.innerHTML = addPictureTagInHtmlCode({ currentImageTag: imgSrcUrls[i], variants: this.variants, mediaImageUrl })
        }
          }                
          return div.innerHTML;
        }
      }
    },
    pushRoute() {
      this.$router
        .push({
          name: "LandingPage",
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
  <style lang="scss">
html {
  scroll-behavior: smooth;
}
.arrow-top-margin-130 {
  margin-top: 130px;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 18px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.landing-page-hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.landing-page-hero-image {
  height: 62.5%;
  width: 100%;
}
.landing-page-image {
  height: auto;
  width: 100%;
}
.landing-page-top-row {
  display: flex;
}
.landing-page-row {
  display: flex;
}
.landing-page-col-6 {
  width: 50%;
  display: block;
  // margin-top:auto;
  //margin-bottom:auto;
}
.landing-page-content-maxwidth-750 {
  max-width: 750px;
}
.landing-page-link {
  color: #2e5d86 !important;
  & .landing-page-icon {
    background-image: url("@/assets/landing-page-arrow.png");
    max-width: 22px;
    height: 16px;
    background-position: right center;
    background-size: contain;
  }
}
.landing-page-link:hover {
  color: #ccb7ff !important;
  & .landing-page-icon {
    background-image: url("@/assets/landing-page-hover-arrow.png");
    max-width: 22px;
    height: 16px;
    background-position: right center;
    background-size: contain;
  }
}
@media screen and (min-width: 601px) {
  .landing-page-bottom-margin-150 {
    margin-bottom: 150px;
  }
  .landing-page-top-margin-150 {
    margin-top: 150px;
  }
}
@media screen and (max-width: 601px) {
  .arrow {
    display: none;
  }
  .landing-page-hero-text {
    display: none;
  }
  .landing-page-hero-image {
    height: 62.5%;
    width: 100%;
  }
  .landing-page-row {
    display: block;
    text-align: center;
  }
  .landing-page-top-row {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .landing-page-col-6 {
    width: 100%;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
  .landing-page-link {
    justify-content: center;
  }
}
</style>
  <style src="./LandingComponent.scss" lang="scss" scoped /> 