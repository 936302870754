
const vueAppImageApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;
 
    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                const error = (data) || response.statusText;
                return Promise.reject(error);
            } else {
                return data;
            }
        });
    }

export function doPostIncident(incidentObj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(incidentObj
        )
    };
    return fetch(`${vueAppImageApiUrl}/incident`, requestOptions).then(handleResponse);
}

 