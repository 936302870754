<template>
  <div>
    <v-row
      :class="newUser ? 'pt-md-6 mt-md-12 pt-lg-0 mt-lg-0' : ''"
      v-if="categoryContent"
    >
      <!---------------Desktop view------------------------------------>
      <v-card

        :class="isIpad ? 'mt-0' : ''"
        v-if="
          heroContent.ca &&
          getImageCustomAttributeValue(heroContent.ca, 'image') != undefined
        "
        class="
          d-none d-sm-flex
          flex-row flex-start
          categorybaseproduct-hero-container
        "
        flat
        :min-height="getImageHeight"
      >
        <v-col
          cols="12"
          class="text-left d-flex align-center justify-left mt-auto mb-auto"
        >
          <v-card
            flat
            rounded="0"
            :min-height="getImageHeight"
            color="rgba(0,0,0,0.0)"
            class="
              text-center
              categorybaseproduct-hero-content
              d-flex
              align-center
              justify-left
            "
          >
            <div class="px-3">
              <h1
                class="
                  v-card__text
                  text-uppercase
                  px-12
                  pb-2
                  pa-0
                  h1
                  ls-n10
                  text-left
                  fontcolor-brown
                "
              >
                {{ heroContent.n }}
              </h1>
              <v-card-text
                class="
                  fontsize-16
                  line-height-18
                  pa-0
                  mt-md-2 mt-lg-2
                  body-font
                  px-12
                  ls-n10
                  text-left
                  fontcolor-brown
                "
                v-html="getCustomAttributeValue(heroContent.ca, 'description')"
              ></v-card-text>
            </div>
          </v-card>
            <picture class="mt-2">
            <template v-for="imgWidth in variants" :key="imgWidth">
              <source  :media="`(min-width:${imgWidth}px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(getImageCustomAttributeValue(heroContent.ca, 'image')) +'_'+imgWidth+'.webp'" :height="getImageHeight"   :style="`(width:${getImageHeight}px)`"  >
            </template>
            <img :src="mediaImageUrl+'/' + fetchProductImageName(getImageCustomAttributeValue(heroContent.ca, 'image')) +'.webp'" :alt="heroContent.n"
          :title="heroContent.n" :height="getImageHeight"  onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';" :style="`(width:${getImageHeight}px)`" >
          </picture>

          
        </v-col>
      </v-card>
      <v-card
        v-else-if="heroContent.ca"
        class="
          d-none d-sm-flex
          flex-row flex-start
          categorybaseproduct-hero-container
        "
        :min-height="getImageHeight"
        tile
        flat
      >
        <v-col
          cols="12"
          class="text-left d-flex align-center justify-left mt-auto mb-auto"
        >
          <v-card
            flat
            :min-height="getImageHeight"
            tile
            color="rgba(0,0,0,0.0)"
            class="
              text-center
              categorybaseproduct-hero-content
              pr-12
              d-flex
              align-center
              justify-left
            "
          >
            <div>
              <h1
                class="
                  v-card__text
                  text-uppercase
                  pa-0
                  mx-auto
                  text-white
                  h1
                  ls-n10
                  herocontainer-max-width-800
                "
              >
                {{ heroContent.n }}
              </h1>
            </div>
          </v-card>

           <picture class="mt-2">
            <template v-for="imgWidth in variants" :key="imgWidth" >
              <source :media="`(min-width:${imgWidth}px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'_'+imgWidth+'.webp'" :height="getImageHeight"  :style="`(width:${getImageHeight}px)`">
            </template>
            <img :src="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'.webp'" :alt="heroContent.n"
          :title="heroContent.n" :height="getImageHeight" :style="`(width:${getImageHeight}px)`" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
        </picture>

         
        </v-col>
        <v-col cols="5"></v-col>
      </v-card>
      <v-card
        v-else
        class="
          d-none d-sm-flex
          flex-column flex-start
          categorybaseproduct-hero-container
        "
        :min-height="getImageHeight"
        tile
        flat
      >
       <picture class="mt-2">
            <template v-for="imgWidth in variants" :key="imgWidth">
              <source  :media="`(min-width:${imgWidth}px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'_'+imgWidth+'.webp'" :height="getImageHeight" :style="`(width:${getImageHeight}px)`">
            </template>
            <img :src="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'.webp'" :alt="heroContent.n009"
          :title="heroContent.n" :height="getImageHeight" :style="`(width:${getImageHeight}px)`" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';" loading="lazy">
        </picture>
 
      </v-card>
      <!-------------------------------------Mobile view----------------------------------------->
      <v-card
        v-if="
          heroContent.ca &&
          getImageCustomAttributeValue(heroContent.ca, 'image') != undefined
        "
        flat
        class="d-sm-none mx-auto text-center px-3 my-3 mt-0"
        tile
        width="100%"
      >
        <v-card
        height="350"
          flat
          color="rgba(0,0,0,0.0)"
          class="text-center d-flex rounded-0  pt-2"
        >
          <picture style="width:100vw;">
            
              <source :media="`(min-width:360px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(getImageCustomAttributeValue(heroContent.ca, 'image')) +'_360.webp'" style="width:100%; height:100%;">
           
            <img :src="mediaImageUrl+'/' + fetchProductImageName(getImageCustomAttributeValue(heroContent.ca, 'image')) +'.webp'" :alt="heroContent.n" :title="heroContent.n" style="width:100%; height:100%; object-fit: cover;" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
          </picture>

          
          <div
            class="
              categorybaseproduct-color-overlay
              d-flex
              align-center
              flex-column
              justify-center
              h-100
              px-2
            "
          >
            <h1
              class="
                v-card__text
                text-uppercase
                line-height-33
                text-white
                fontsize-28
                ls-n10
                text-center
                mx-auto
                pb-2
                px-6
                font-weight-regular
              "
            >
              {{ heroContent.n }}
            </h1>
            <div
              class="
                text-white
                fontsize-12
                line-height-18
                px-3
                text-center
                body-font
                mx-auto
                herocontainer-max-width-635
                ls-n10
                pb-8
              "
             v-html="getCustomAttributeValue(heroContent.ca, 'description')"
            ></div>
          </div>
        </v-card>
      </v-card>

      <v-card
        v-else-if="heroContent.ca"
        flat
        class="d-sm-none mx-auto text-center px-3"
        tile
      >
        <v-card
          height="350"
          flat
          color="rgba(0,0,0,0.0)"
          class="text-center d-flex"
        >
           <picture class="mt-2">
            <template v-for="imgWidth in variants" :key="imgWidth">
              <source  :media="`(min-width:${imgWidth}px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'_'+imgWidth+'.webp'"  style="width:100%">
            </template>
              <img :src="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'.webp'" :alt="heroContent.n" :title="heroContent.n"  style="width:100%" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
          </picture>

          <div
            class="
              categorybaseproduct-color-overlay
              d-flex
              align-center
              flex-column
              justify-center
              h-100
              px-6
            "
          >
            <h1
              class="
                v-card__text
                text-uppercase
                pa-0
                line-height-33
                text-white
                h1
                ls-n10
                text-center
                mx-auto
                px-6
              "
            >
              {{ heroContent.n }}
            </h1>
          </div>
          <slot />
        </v-card>
      </v-card>
      <v-card v-else class="d-sm-none mx-auto text-center px-3" tile flat>
        <v-card
          height="350"
          flat
          color="rgba(0,0,0,0.0)"
          class="text-center d-flex"
        >
          <picture class="mt-2" style="width:100vw;">
            <template v-for="imgWidth in variants" :key="imgWidth">
              <source  :media="`(min-width:${imgWidth}px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'_'+imgWidth+'.webp'"  style="width:100%">
            </template>
              <img :src="mediaImageUrl+'/' + fetchProductImageName(heroData.image) +'.webp'" :alt="heroContent.n" :title="heroContent.n"  style="width:100%; height:100%; object-fit: cover;" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
          </picture>
          <div
            class="
              categorybaseproduct-color-overlay
              mx-auto
              d-flex
              align-center
              justify-center
              mt-auto
              mb-auto
            "
          ></div>
        </v-card>
      </v-card>
      <!-------------------------------------------------------------------------------------->
    </v-row>
  </div>
</template>
<script>
 import { attribute, customAttributeValueNew } from "@/services/util.service";
import imageData from "@/assets/c4aa164d9b01387a88228c67620f7faf861b5544.png";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "CategoryHeroContentComponent",
   
  props: ["breadcrumbData", "categoryContent"],
  computed: {
    isIpad: function () {
      return this.$vuetify.display.smOnly || this.$vuetify.display.mdOnly
        ? true
        : false;
    },
    isIpadOrMobile: function () {
      return this.$vuetify.display.mdAndDown ? true : false;
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    productAttributes() {
      return this.$store.state.productList.attributeList;
    },
    lazyLoadImagePathUrl() {
      return import.meta.env.VITE_LAZY_SRC_URL;
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    imageBaseParams() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PARAMS;
    },

    heroContent() {
      return this.$store.state.layout.heroContent;
    },
    getImageHeight() {
      if (this.$vuetify.display.lgAndUp) {
        return "454";
      } else if (this.$vuetify.display.mdOnly) {
        return "406";
      } else if (this.$vuetify.display.smOnly) {
        return "300";
      }
      return "454";
    },
    newUser() {
      if (this.$vuetify.display.smAndDown) return false;
      let userVisits = this.$store.state.layout.user.userVisits;
      if (userVisits && Number(userVisits) > 1) return false;
      else return true;
    },
  },
  data: () => ({
    mediaImageUrl,
     variants: [1366, 768, 360],
    heroImageHeight: "454",
    heroImageWidth: "454",
    heroData: {
      image: imageData,
    },
  }),
  methods: {
       fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    setImage() {
      event.target.src = this.heroData.image;
    },

    categoryImageBasePathUrl() {
      return import.meta.env.VITE_MAGENTO_API_URL;
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValueNew(customAttributes, attributeCode);
    },
    getImageCustomAttributeValue(customAttributes, attributeCode) {
      let imagePath = customAttributeValueNew(customAttributes, attributeCode);
      if (imagePath == "") {
        return undefined;
      } else if (imagePath.includes("/media/catalog/category/")) {
        return this.categoryImageBasePathUrl() + imagePath;
      } else if (!imagePath.includes("/media/catalog/category/")) {
        return (
          this.categoryImageBasePathUrl() +
          "/media/catalog/category/" +
          imagePath
        );
      } else {
        return this.heroData.image;
      }
    },

    getAttribute(customAttributes, attributeCode) {
      return attribute(customAttributes, attributeCode, this.productAttributes);
    },

    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValueNew(customAttributes, attributeCode);
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="scss" >
.categorybaseproducts-filter-button .v-input__control .v-input__slot {
  padding-left: 10px !important;
}
.categorybaseproducts-filter-button .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.categorybaseproducts-filter-button:hover .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.categorybaseproducts-filter-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-filter-button.v-input--is-label.v-input
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-filter-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-filter-button .theme--light.v-label {
  color: #2850da !important;
}
.categorybaseproducts-sortby-button.theme--light.v-select
  .v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-filter-button:hover
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: #2850da !important;
}
.categorybaseproducts-filter-button
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: #2850da !important;
  font-size: 24px !important;
}
.categorybaseproducts-filter-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}
 
.categorybaseproducts-filter-button
  .v-input__control
  .v-select__slot
  .v-select__selections {
  justify-content: center;
}
.categorybaseproducts-filter-button.v-select.v-text-field input {
  display: none;
}

.close-filter-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
  opacity: 0 !important;
}

.close-filter-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0 !important;
}

.categorybaseproduct-hero-image .v-image__image--cover {
  background-size: 100% 100% !important;
}
</style>
<style src="./CategoryBaseProducts.scss" lang="scss" scoped />