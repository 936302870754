<template>
  <v-container class="py-0 my-0">
    <v-dialog
      v-model="subscriptionDialog"
      :max-width="1100 ? 1100 : 800"
      :scrollable="false"
      :overlay-opacity="0.5"
    >
      <v-card
        flat
        tile
        :img="isDesktop ? `http://media.crane.com/9441/popup_v3.jpg` : ''"
        :class="isDesktop ? '' : 'dialog-background'"
      >
        <v-card-title class="px-2 px-sm-4 pb-0 pt-0 pt-sm-4">
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                @click="subscriptionDialog = false"
                class="dailog-close-button"
                icon
              >
              
                <v-icon :icon="mdiWindowClose" :large="isDesktop" :small="!isDesktop"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-1 pb-sm-3 px-2 px-sm-4">
          <v-row no-gutters class="mx-0">
            <v-col cols="12" sm="6">
              <v-row align="center" class="px-sm-6 px-2 pa-0 ma-0">
                <v-form ref="subscribeForm" v-model="footerFormValid">
                  <div class="pa-md-1">
                    <v-col cols="12" class="pt-0 mb-sm-4">
                      <span
                        class="
                          primary--text
                          h3
                          font-weight-regular
                          text-uppercase
                        "
                        >{{ stayInTouch }}</span
                      >
                      <p
                        class="
                          subscribe-fontcolor-black-darken
                          body-font
                          ls-n10
                          mb-0
                          pt-2 pt-sm-4
                        "
                      >
                        Be the first to know about special promotions, new
                        collections and more when you sign up for our emails!
                      </p>
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-2 pt-sm-3 d-md-block">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field
                            @blur="resetEmailValidation"
                            label="Your Email"
                            id="emailTextBox"
                            class="
                              footerpage_email_textbox
                              fontsize-14
                              regular-font
                            "
                            solo
                            flat
                            dense
                            :rules="emailRules"
                            tile
                            v-model="email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            depressed
                            :block="!isDesktop"
                            color="primary"
                            class="
                              white--text
                              mr-n3
                              text-uppercase
                              footerpage_submit_email_btn
                              body-font
                            "
                            @click="submit()"
                            >SUBMIT</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="!isDesktop"
            class="
              regular-font
              subscribe-fontcolor-black-darken
              mx-0
              px-sm-6 px-2
            "
          >
            <v-col cols="12" sm="3">
              <router-link to class="subscribe-fontcolor-black-darken">
                <span @click="subscriptionDialog = false">No, thank you</span>
              </router-link>
            </v-col>
            <v-col cols="12" sm="9" class="pt-0 pt-sm-3">
              By signing up, you agree to our and
              <router-link
                to
                @click.native="goToLink('https://www.crane.com/privacy-policy')"
                title="Privacy Policy"
                class="subscribe-fontcolor-black-darken"
              >
                <span>Privacy Policy</span> </router-link
              >and
              <router-link
                to
                @click.native="goToLink('https://www.crane.com/terms-of-use')"
                title="Terms of Service"
                class="subscribe-fontcolor-black-darken"
              >
                <span>Terms of Service</span> </router-link
              >.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row
        v-if="isDesktop"
        class="
          regular-font
          subscribe-fontcolor-black-darken
          bottom-div-background
          mx-0
        "
      >
        <v-col cols="12" sm="3">
          <router-link to class="subscribe-fontcolor-black-darken">
            <span @click="subscriptionDialog = false">No, thank you</span>
          </router-link>
        </v-col>
        <v-col cols="12" sm="9" class="pt-0 pt-sm-3">
          By signing up, you agree to our and
          <router-link
            to
            @click.native="goToLink('https://www.crane.com/privacy-policy')"
            title="Privacy Policy"
            class="subscribe-fontcolor-black-darken"
          >
            <span>Privacy Policy</span>
          </router-link>
          and
          <router-link
            to
            @click.native="goToLink('https://www.crane.com/terms-of-use')"
            title="Terms of Service"
            class="subscribe-fontcolor-black-darken"
          >
            <span>Terms of Service</span> </router-link
          >.
        </v-col>
      </v-row>
    </v-dialog>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </v-container>
</template>
<script>
/*eslint-disable*/
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import { mdiWindowClose } from '@mdi/js'

export default {
  name: "SubscriptionDialog",
  data: () => ({
    mdiWindowClose,

    subscriptionDialog: false,
    footerFormValid: false,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    stayInTouch: "LET'S STAY IN TOUCH",
    email: null,
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
  }),
  components: { SnackbarComponent },
  computed: {
    isDesktop: function () {
      return true ? false : true;
    },
  },
  methods: {
    goToLink(pageLink) {
      window.location.replace(pageLink);
       
    },
    submit: function () {
      if (this.$refs.subscribeForm.validate()) {
        this.$store
          .dispatch("emailSubscriber/fetchEmailSubscriber", {
            email: this.email,
          })
          .then((status) => {
            if (status === "you have successfully subscribed.") {
              this.snackbar = {
                message: status,
                color: "success",
                show: true,
                timeout: 5000,
              };
            } else {
              this.snackbar = {
                message: status,
                color: "error",
                show: true,
                timeout: 5000,
              };
            }
          })
          .catch((error) => {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 5000,
            };
          });
        this.email = null;
        this.$refs.subscribeForm.resetValidation();
        this.subscriptionDialog = false;
      }
    },

    resetEmailValidation() {
      this.$refs.subscribeForm.resetValidation();
    },
  },
};
</script>
<style>
.bottom-div-background {
  background: rgba(217 218 221 / 20%);
}
.dialog-background {
  background-color: #97cfe3 !important;
}
.dailog-close-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
  opacity: 0 !important;
}
.dailog-close-button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0 !important;
}
.subscribe-fontcolor-black-darken {
  color: #1d1d1d !important;
}
</style>