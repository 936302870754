<template>
  <div>
    <v-breadcrumbs
      :items="breadsCrumbItems"
      class="apps-breadcrumb pl-0"
      large
      divider="/"
    >
    <template v-slot:title="{ item }">
     <a
          href="#"
          :class="[item.disabled ? 'disabled' : 'text-color-black']"
          class="text-decoration-none"
          @click.prevent="categoryProducts(item.href)"
          >{{ item.title }}</a
        >
    </template>
    </v-breadcrumbs>
  </div>
</template>
<script>
import {
  customAttributeValue,
  goToCategoryPageRouting,
  getCategoryOne,
  googleAanalyticsEventCategory,
} from "@/services/util.service";
import { mapState } from "vuex";

export default {
  name: "BreadcrumbComponent",
  props: ["breadcrumbVal", "search"],
  data: () => ({
    breadsCrumbItems: [],
    subCategory: null,
    breadsCrumbArray: [],
    newArray: [],
  }),
  computed: {
    ...mapState({
      searchTerms: (state) => state.catalogBrowser.searchTerms,
      keywordsSearch: (state) => state.catalogBrowser.searchTerms.keywords,
    }),
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
  },
  watch: {
    keywordsSearch() {
      this.createBreadsCrumbArray();
    },
  },
  methods: {
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    createBreadsCrumbArray() {
      this.newArray = [];
      this.breadsCrumbItems = [];
      if (this.breadcrumbVal == "keyword") {
        let obj = {
          title: "Search results for : '" + this.searchTerms.keywords + "'",
          href: "",
          disabled: true,
        };
        this.newArray.push(obj);
      } else {
        let newObj = this.getParentId(this.breadcrumbVal);
        if (newObj.length > 0) {
          let obj = {
            title: newObj[0].n,
            href: newObj[0].id,
            disabled: true,
          };
          this.newArray.push(obj);
          while (newObj[0].pid != 2) {
            newObj = this.getParentId(newObj[0].pid);
            obj = {
              title: newObj[0].n,
              href: newObj[0].id,
              disabled: false,
            };
            this.newArray.push(obj);
          }
        }
      }
      this.newArray.push({
        title: "Home",
        href: "Home",
        disabled: false,
      });
      let filterArray = this.newArray.reverse();
      this.breadsCrumbItems = filterArray;
    },

    getParentId(categoryId) {
      return this.categoryListIdWise.filter((element) => {
        return element.id === categoryId;
      });
    },
    async categoryProducts(categoryId) {
      this.$store.dispatch("headerCategoryList/setCategoryId", categoryId);
      localStorage.removeItem("cr_subCategoryItemId");
      let data = localStorage.getItem("cr_subCategoryId");
      if (data >= categoryId) {
        localStorage.removeItem("cr_subCategoryId");
      }
      if (categoryId === "Home") {
        this.$router.push({
          name: "HomePage",
        });
      } else {
        if (this.categoryDetailsList) {
          let categoryOne = await getCategoryOne(
            this.categoryDetailsList,
            categoryId
          );
          if (categoryOne) {
            this.$store.dispatch("layout/setHeroContent", categoryOne);
            console.log(categoryOne.n, categoryOne);
            let vm = this;
            googleAanalyticsEventCategory("browse_category", "ecommerce", {
              name: categoryOne.n,
              id: categoryId,
            });
            goToCategoryPageRouting(vm, categoryOne);
            this.$store
              .dispatch("catalogBrowser/search", {
                fullCatalog: window.craneCatalog,
                keywords: "",
                filters: [{ label: "Category", prop: "ci", value: categoryId }],
                pageNumber: 1,
                pageSize: 9,
                sortBy: "position",
              })
              .catch((error) => {
                error;
              });
          }
        }
      }
    },
  },
  created() {
    this.createBreadsCrumbArray();
  },
};
</script>
<style src="./Breadcrumb.scss" lang="scss" scopped />