<template>
  <div>
    <v-footer
      class="py-sm-0 px-sm-0 pb-0 px-0 page-footer footer-component-border"
    >
      <v-row
        align="center"
        justify="center"
        class="d-flex flex-column-reverse flex-sm-row pa-0 ma-0"
      >
        <v-col
        class="footer-component-background pb-md-0 px-sm-10 px-md-6 pt-md-5 pt-sm-4 pt-6 pl-lg-12 pr-lg-12"
          cols="12"
          md="8"
          sm="7"
          lg="8"
          xl="9"
        >
          <v-container class="px-lg-12 pb-md-0">
            <v-row class="ma-0">
              <v-col class="regular-font pr-lg-8">
                <div v-html="footerContent()"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          md="4"
          sm="5"
          lg="4"
          xl="3"
          class="pb-0  pt-md-0 d-sm-flex align-center white"
        >
          <v-row align="center" class="px-sm-6 px-2 pa-0 ma-0">
            <v-form ref="footerForm" v-model="footerFormValid">
              <div class="pa-md-1">
                <v-col cols="12" class="pt-md-0 mb-4 mb-sm-0">
                  <span
                    class="footer-fontcolor-primary h3 font-weight-regular text-uppercase"
                    >{{ stayInTouch }}</span
                  >
                  <p
                    class="footer-fontcolor-black-darken body-font ls-n10 mb-0 pt-4 mt-md-4 mt-sm-4 pt-sm-0"
                  >
                    Be the first to know about special promotions, new
                    collections and more when you sign up for our emails!
                  </p>
                </v-col>
                <v-col cols="12" class="pb-0 pt-2 pt-sm-3 d-sm-none d-md-block">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Your Email"
                      single-line
                      hide-details="auto"
                      id="subscriberEmailId"
                      :rules="emailRules"
                      name="subscriberEmailId"
                      @blur="resetEmailValidation"
                      v-model="email"
                      class="footerpage_email_textbox fontsize-14 regular-font"
                    >
                    <template v-slot:append-inner >
                      <v-btn
                          class="footer-component-background text-white mt-n2 text-uppercase  footerpage_submit_email_btn body-font"
                          id="subscriberSubmitId"
                          name="subscriberSubmitId"
                          @click="submit()"
                          >SUBMIT</v-btn
                        >
                     </template>


                      </v-text-field>

                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0 pt-2 pt-sm-3 d-none d-sm-block d-md-none"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Your Email"
                      single-line
                      id="emailTextBox"
                      :rules="emailRules"
                      v-model="email"
                      class="footerpage_email_textbox regular-font"
                    >
                      </v-text-field>
                      <v-btn
                          class="footer-component-background text-white mt-2 text-uppercase  footerpage_submit_email_btn body-font"
                          @click="submit()">SUBMIT</v-btn
                        >
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-form>
              <span class="mt-7 ml-auto caption">{{ product.version }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    <SubscriptionDialog />
  </div>
</template>

<script>
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import SubscriptionDialog from "@/components/common/SubscriptionDialog.vue";
import { listrakSubscribe } from "@/services/util.service";
export default {
  name: "FooterComponent",
  components: { SnackbarComponent, SubscriptionDialog },
  data() {
    return {
      footerBlockId: 10,
      listSubHeaderColor: "red",
      footerFormValid: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      stayInTouch: "LET'S STAY IN TOUCH",
      email: null,
      snackbar: {
        show: false,
        message: null,
        color: null,
        timeout: 0,
      },
    };
  },

  computed: {
     product: function(){
      return this.$store.state.layout.product
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    footerBlockContent: function () {
      return this.$store.state.layout.cmsBlockContent;
    },
  },
  methods: {
    footerContent() {
      if (this.footerBlockContent.length) {
        let data = this.footerBlockContent.filter((element) => {
          return element.id === this.footerBlockId;
        });
        if (data.length > 0) {
          return data[0].content;
        }
      }
    },
    submit: async function () {
      let validate=await this.$refs.footerForm.validate()
      if (validate && validate.valid) {
         listrakSubscribe(this.email);
        this.snackbar = {
          message: "you have successfully subscribed.",
          color: "success",
          show: true,
          timeout: 5000,
        };

        this.email = null;
      
      }
    },

    resetEmailValidation() {
      this.$refs.footerForm.resetValidation();
    },
  },
  
};
</script>
<style src="./FooterComponent.scss" lang="scss"/>
