<template>
  <div v-resize="OnResize">
    <v-container class="px-5 px-sm-3 px-md-3 px-lg-3 pt-4 pb-1" ref="divOne">
      <div :class="isIpad ? 'd-flex flex-column-reverse' : 'pt-lg-5 pb-lg-2'">
        <div :class="newUser ? 'pt-lg-6 mt-lg-12' : ''">
          <v-row>
         
            <v-col cols="12"  class="py-0 d-block d-sm-none d-md-none d-lg-block">
              <div v-if="categoryListIdWise">
                <BreadcrumbComponent :breadcrumbVal="breadcrumbData" :key="breadcrumbData" />
              </div>
            </v-col>
          </v-row>
        </div>
        <CategoryHeroContent v-if="!isMobile" :categoryContent="categoryPageContent" :key="breadcrumbData" ></CategoryHeroContent>
      </div>

      <v-row v-if="isIpadOrMobile && searchResults.page.length" >
        <v-col cols="12" class="pa-0">
          <v-col cols="12" sm="4" >
            <span>
              <ul class="pl-0" v-for="(value, index) in enabledFilters" :key="index">
                <li class="d-flex justify-space-between pb-1" v-if="value.label !='Category'">
                  <div>
                    <b>
                      <span class="text-uppercase">{{ value.label }}</span>
                    </b>
                    : {{ value.value }}
                  </div>
                  <v-hover v-slot:default="{ isHovering }">
                   
                    <v-icon :icon="mdiClose"
                    :color="isHovering ? 'primary' : ''"
                      @click.stop="deleteFilter(value,index)"
                      class="searchproducts-cancel-buttons cursor-pointer transparent-background"/>
                  </v-hover>
                </li>
              </ul>
            </span>
          </v-col>
        </v-col>
        <v-col cols="12"  class="py-0 d-none d-sm-block d-md-block my-0">
              <div v-if="categoryListIdWise">
                <BreadcrumbComponent :breadcrumbVal="breadcrumbData" :key="breadcrumbData" />
              </div>
            </v-col>
        <v-col cols="12" sm="4" class="d-lg-none pt-5 pb-0">
          <v-menu
            allow-overflow
           location="bottom"
            :close-on-content-click="closeFilterMenu"
             v-model="menu"
          >
            <template v-slot:activator="{ props }">
            
             <v-btn
              color="primary"
              v-bind="props"
              variant="outlined"
              @click="setFilterValue"
               block
               class="float-left fontsize-16 font-weight-400 regular-font"
               rounded="0"
               size="large"
            >
              FILTERS
            </v-btn>
            </template>

            <v-list block	 >
              <v-list-item>
                <v-list-item-title class="filter-component-width">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                      color="primary"
                        class="text-uppercase ls-0 body mt-1 pb-1 productpage-button"
                        variant="outlined"
                        rounded="0"
                        block
                        @click="clearFilter()"
                      >CLEAR</v-btn>
                    </v-col>
                  </v-row>
                  <FilterComponent
                    @clearAction="actionClear"
                    :filterList="availalableFilters"
                    :inProgress="inProgress"
                    :enabledFilters="enabledFilters"
                    @applyNewFilter="applyFilter"
                    :key="availalableFilters"
                  ></FilterComponent>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col lg="10" md="4" sm="4" cols="3" class="pt-5 pr-md-3 pr-0 pr-sm-2">
          <p class="text-uppercase Whyte-Regular text-primary my-3 mr-1 fontsize-16 ls-n01 font-weight-400 categorypage_fontcolor_greyshade8b searchproducts-sortby float-left"
          >Sort by:</p>
        </v-col>
        <v-col cols="9" lg="2" md="4" sm="4" class="py-0 pt-5 pb-0 mb-0 px-3">
          <v-select
            :items="criteria"
            class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 categorybaseproducts-sortby-button select-change-icon regular-font"
            dense
            flat
            attach
            solo
            ref="sortAndNextPage"
            item-title="label"
            item-value="value"
            background-color="transparent"
            v-model="sortBy"
            :menu-icon="mdiPlus"
            density="compact"
            variant="outlined"
            @update:modelValue="sortAndNextPage()"
          ></v-select>
        </v-col>
        <v-col md="12" sm="12" cols="12" class="py-0">
          <p
            class="h3 regular-font mb-4"
            v-if="searchPageContent"
          >Search results for : '{{ keywords }}'</p>
        </v-col>
      </v-row>

      <CategoryHeroContent :key="breadcrumbData"  v-if="isMobile" :categoryContent="categoryPageContent"></CategoryHeroContent>
      <v-row class="d-none d-sm-flex mt-4" v-if="!isIpadOrMobile">
        <v-col cols="12" lg="3" md="4" sm="4">
          <span>
            <ul class="pl-0" v-for="(value, index) in enabledFilters" :key="index">
              <li class="d-flex justify-space-between pb-1" v-if="value.label !='Category'">
                <div>
                  <b>
                    <span class="text-uppercase">{{ value.label }}</span>
                  </b>
                  : {{ value.value }}
                </div>
                <v-hover v-slot:default="{ isHovering }">
               
                  <v-icon :icon="mdiClose"
                  :color="isHovering ? 'primary' : ''"
                    @click.stop="deleteFilter(value,index)"
                    class="ccategorybaseproduct-cancel-buttons cursor-pointer"/>
                </v-hover>
              </li>
            </ul>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="searchResults.page.length">
        <v-col lg="3" md="1" sm="1" class="d-none d-lg-block pt-12 pr-md-12">
          <v-row justify="center" class="pt-6">
            <FilterComponent
              :filterList="availalableFilters"
              :inProgress="inProgress"
              :enabledFilters="enabledFilters"
              @applyNewFilter="applyFilter"
              :key="availalableFilters"
            />
          </v-row>
        </v-col>
        <v-col cols="12" lg="9" md="12" sm="12" class="pl-lg-12" ref="divOnesecond">
          <v-row>
            <v-row v-if="!isIpadOrMobile" align="center" >
              <v-col lg="6" md="4" sm="4" cols="4" class="py-0">
                <p
                  class="h3 regular-font mb-1"
                  v-if="searchPageContent"
                >Search results for : '{{ keywords }}'</p>
              </v-col>
              <v-col lg="2" md="4" sm="4" cols="4" class="py-0">
                <p
                  class="text-uppercase primary--text my-3 mr-2 fontsize-14 body float-right ls-n01 font-weight-400 categorypage_fontcolor_greyshade8b"
                >Sort by:</p>
              </v-col>
              <v-col lg="4" md="3" sm="3" cols="4" class="pb-0 pt-6 pl-2 pr-7">
                <v-select
                  :items="criteria"
                  class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 categorybaseproducts-sortby-button categorybaseproducts-select-button select-change-icon regular-font"
                  attach
                  flat
                  solo
                  ref="sortAndNextPage"
                  v-model="sortBy"
                  item-title="label"
                  item-value="value"
                  background-color="transparent"
                  :menu-icon="mdiPlus"
                  density="compact"
                  variant="outlined"
                      @update:modelValue="sortAndNextPage()"
                ></v-select>
              </v-col>
            </v-row>
          </v-row>
          <v-row v-if="inProgress">
            <v-col
              v-for="(i, index) in 9"
              v-bind:key="index"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="pr-lg-4 cursor-pointer"
            >
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-if="searchResultsNew && searchResultsNew.page &&searchResultsNew.page.length > 0" class="mt-0">
            <v-col
              v-for="(product, index) in searchResultsNew.page"
              v-bind:key="index"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="pr-lg-4 cursor-pointer pt-lg-6 mb-0 pb-0"
            > 
            <picture>
              <source  :media="`(min-width:100px)`" :srcset="mediaImageUrl+'/' + fetchProductImageName(product.i) +'_360.webp'" style="width:100%">
              <img  @click="gotoProductDetails(product.n,product)" :src="mediaImageUrl+'/' + fetchProductImageName(product.i) +'.webp'"   style="width:100%" :alt="product.n" :title="product.n" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
            </picture>
          
              <v-row v-if="product.t=='configurable'" class="mt-1">
                <v-col cols="12" class="pb-0">
                <span v-if="configurableProductOptionsCheck(product.cpo,'choose_color')">
                  <div   v-for="(color,i) in product.cpd" :key="i" class=" d-inline-block">
                  <v-tooltip  location="bottom"  >
                  <template v-slot:activator="{  props }">
                  <div
                    v-bind="props"
                     v-on="on"
                     class="search-color-circle d-flex justify-center align-center cursor-pointer"
                      v-bind:class="{
                        'search-color-circle-border': color.letter_label == searchResultsNew.page[index].sc
                      }"
                  >
                    <div
                      class="search-product-color-circle cursor-pointer"
                     :style="'background-color:' + setHexaColor(color.letter_label)"
                       @click="setColor(color,index)"
                    ></div>
                  </div>
                  </template>
                  <span>{{ color.letter_label }}</span>
                  </v-tooltip>

                  </div>
                  </span>
                </v-col>
               </v-row>
              <v-row class="py-0 mt-2 mb-0">
                <v-col cols="7" class="pb-0">
                  <h1
                    class="float-left fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
                  >{{ product.n }}</h1>
                </v-col>
                 <v-col cols="5" class="pb-0" v-if="product.t !='configurable'">

                  <p
                    v-if="product.lp"
                    class="float-right fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
                  >
                    <span v-if='product.lp.includes("price:")'>
                    From ${{ replacePriceString(product.lp) }} {{ product.u }}
                  </span>
                   <span v-else>

                    From ${{ product.lp }}
                    {{ product.u }}
                    </span>
                  </p>
                  <div
                    v-else
                    class="float-right fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
                  >
                    <div v-if="product.sp">
                      <s>${{ product.p }}</s> &nbsp;
                      <p class="text-red mb-0">${{ roundUp(product.sp) }}</p>
                      / {{ product.u }}
                    </div>
                    <div v-else-if="product.p != 0">
                      <p>
                        ${{ priceFormatter(product.p) }} /
                        {{ product.u }}
                      </p>
                    </div>
                    <div v-else>
                      <p>TBD</p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="5" class="pb-0" v-if="product.t =='configurable'">
                  <p
                    v-if="product.cpr"
                    class="float-right fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
                  >
                    {{ product.cpr }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pr-5 pt-10 pb-0 ma-0" align="center" justify="end">
            <v-col cols="12" sm="6" md="8" lg="8" class="pa-0">
              <v-col
                cols="12"
                sm="6"
                md="8"
                lg="8"
                class="pa-0 custom-pagination d-flex flex-column"
              >
                 <v-pagination
                  v-if="pageSize != 'ALL'"
                  class="categorybaseproducts-pagination-button d-flex justify-flex-start ma-auto"
                  v-model="pageNumber"
                  :length="totalVisiblePages"
                  :total-visible="5"
                  elevation="0"
                  @update:modelValue="pageViewChange()"
                  rounded="circle"
                  size="small"
                >
                <template #prev>
                <v-col v-if="pageNumber > 1" class="pa-1"><span  class="cursor-pointer  text-body-2 font-weight-regular text-uppercase line-height-18 px-0 mr-n5" @click="prev">Previous</span></v-col>
                </template>
                 <template #next>
                <v-col v-if="pageNumber !== totalVisiblePages"  class="pa-1"><span class="cursor-pointer text-body-2 font-weight-regular text-uppercase line-height-18 px-0 ml-n2" @click="next">Next</span></v-col>
                </template>
                </v-pagination>
              </v-col>
            </v-col>
            <v-col sm="6" md="4" lg="4" class="d-none d-sm-block">
              <v-row align="center" justify="end">
                <p
                  class="mr-2 text-uppercase fontsize-14 ls-n01 font-weight-400 fontcolor-black-darken"
                >Show:</p>
                <v-select
                  class="fontsize-16 body ls-n01 transparent-background fontcolor-black-darken categorybaseproducts-overflow-button"
                  :items="pageNum"
                  v-model="pageSize"
                  item-value="text"
                  item-title="text"
                  target="#dropdown-example"
                  :hide-no-data="true"
                  no-data-text
                  density="compact"
                  variant="outlined"
                  @update:modelValue="pageSizeWiseProduct()"
                ></v-select>
                <p
                  class="ml-2 text-uppercase fontsize-14 ls-n01 font-weight-400 fontcolor-grey-shade8b"
                >Per Page</p>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
       <v-row v-else class="text-center pt-2">
            <v-col
            lg="12"
              md="12"
              sm="12"
              cols="12">
              <v-alert type="error"  density="comfortable" variant="tonal" class="font-weight-300 body-font h3">{{
                noProducts
              }}</v-alert>
            </v-col>
          </v-row>
    </v-container>
  </div>
</template>
<script>
import { mdiPlus, mdiClose } from '@mdi/js'
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import BreadcrumbComponent from "@/components/crane/breadcrumb/Breadcrumb.vue";
import { createHelpers } from "vuex-map-fields";
import { mapState } from "vuex";
import CategoryHeroContent from "@/components/crane/category/CategoryHeroContent.vue";
import FilterComponent from "@/components/crane/filter/FilterComponent.vue";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

import {
 googleAanalyticsEventCategoryPage,
 googleAanalyticsEventSelectContent,
 colorsName,
 priceFormatter
} from "@/services/util.service";
const { mapFields } = createHelpers({
  getterType: "catalogBrowser/getField",
  mutationType: "catalogBrowser/updateField"
});
export default {
  name: "SearchProductsComponent",
  components: {
    FilterComponent,
    CategoryHeroContent,
    BreadcrumbComponent
  },
  props: [
    "criteria",
    "searchPageContent",
    "breadcrumbData",
    "categoryPageContent"
  ],

  computed: {
    ...mapState({
      searchResults: state => state.catalogBrowser.searchResults,
      enabledFilters: state => state.catalogBrowser.enabledFilters,
      searchTerms: state => state.catalogBrowser.searchTerms,
      inProgress: state => state.catalogBrowser.inProgress,
      totalProducts: state => state.catalogBrowser.totalItems,
      availalableFilters: state => state.catalogBrowser.availalableFilters
    }),
    ...mapFields([
      "searchTerms",
      "searchTerms.pageNumber",
      "searchTerms.pageSize",
      "searchTerms.keywords",
      "searchTerms.sortBy",
      "searchTerms.filters"
    ]),
    easingOptions() {
      return {
        duration: this.duration,
        easing: this.easing
      };
    },
    categoryListIdWise: function() {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    lazyLoadImagePathUrl() {
      return import.meta.env.VITE_LAZY_SRC_URL;
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    imageBaseParams() {
      return import.meta.env.VITE_IMAGE_CATEGORY_PARAMS;
    },
    isIpadOrMobile: function() {
      return this.$vuetify.display.mdAndDown ? true : false;
    },
    isIpad: function() {
      return this.$vuetify.display.smOnly || this.$vuetify.display.mdOnly
        ? true
        : false;
    },
    isMobile: function() {
      return this.$vuetify.display.xs ? true : false;
    },
     categoryId: function() {
      return this.$route.meta.id;
    },
    newUser() {
      if (this.$vuetify.display.smAndDown) return false;
      let userVisits = this.$store.state.layout.user.userVisits;
      if (userVisits && Number(userVisits) > 1) return false;
      else return true;
    }
  },
  watch: {
    totalProducts() {
      if (this.pageSize != "ALL") {
        this.totalVisiblePages = Math.ceil(
          this.totalProducts / Number(this.pageSize)
        );
       
      }
    },
     searchResults: {
        handler: function() {
                this.searchResultsNew=JSON.parse(JSON.stringify(this.searchResults));
            },
            deep: true
        },
    keywords(){
      googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
    },
    categoryId(){
    googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
    },
    pageSize() {
      if (this.pageSize != "ALL" && this.totalProducts) {
        this.totalVisiblePages = Math.ceil(
          this.totalProducts / Number(this.pageSize)
        );
      }
    }
  },
  data: () => ({
    mdiPlus,
    mdiClose,

    mediaImageUrl,
    priceFormatter:priceFormatter,
    searchResultsNew:'',
    selectedColor:[],
    closeFilterMenu: false,
    checkClear: false,
    easing: "easeInOutCubic",
    easings: Object.keys(easings),
    duration: 100,
    totalVisiblePages: 0,
    menu: false,
    showDrawarFilter: false,
    pageNum: [
      { text: 9, callback: () => 9 },
      { text: 18, callback: () => 18 },
      { text: 27, callback: () => 27 },
      { text: "ALL", callback: () => "ALL" }
    ],
    noProducts: "No products found.",
  }),
  methods: {
     replacePriceString(str)
    {

       let val= str.replace('price:"','');
           val= val.replace('"}','');
          return val
    },
     fetchProductImageName(imageFullName){
      let fileExtention = imageFullName.split('/')
      let filename = fileExtention[fileExtention.length-1].split('.')[0]
      return filename
    },
     setHexaColor(colorVal) {
      let hexaColor= colorsName.find((obj) => obj.name == colorVal
      );
     if(hexaColor)
     {
      return hexaColor.colorHex
     }
     else
     {
      return '#fffffff'
     }
    },
         setColor(color,index) {
      this.searchResultsNew.page[index].sc = color.letter_label;
      if(color&&  color.file)
      {
      this.searchResultsNew.page[index].i = color.file;
      }

    },
      configurableProductOptionsCheck(configurableProductOptions,lable)
    {
       let result =  configurableProductOptions.filter((element)=> {
        return element.l === lable;

    });

    if(result && result.length > 0)
    {
      return true
    }
    else
    {
      return false;
    }
    },
      prev(){
     if(this.pageNumber !== 1){
       this.pageNumber =this.pageNumber-1
       this.pageViewChange()
     }
    },
    next(){
     if(this.pageNumber !== this.totalVisiblePages){
       this.pageNumber =this.pageNumber+1
              this.pageViewChange()
     }
    },
    async clearFilter() {
      await this.$store.dispatch("catalogBrowser/clearFilter");
      this.closeFilterMenu = true;
      this.pageNumber=1;
      this.$emit("criteriaChanged");
    },
    async deleteFilter(value,index) {
      await this.$store.dispatch("catalogBrowser/deleteSelectedFilter", {
        index: index
      });
      this.pageNumber=1;
      this.$emit("criteriaChanged");
       googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
    },

    setFilterValue() {
      this.closeFilterMenu = false;
    },
    async applyFilter(filterObj) {
      await this.$store.dispatch("catalogBrowser/setNewFilter", filterObj);
      this.closeFilterMenu = true;
      this.pageNumber=1;
      this.$emit("criteriaChanged");
             googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)

    },
    async actionClear() {
      await this.$store.dispatch("catalogBrowser/clearFilter");
      this.closeFilterMenu = true;
    },
    OnResize() {
      setTimeout(() => {
        if (this.$vuetify.display.lgAndUp) {
          this.showDrawarFilter = false;
        }
      }, 200);
    },
    gotoProductDetails(productName,product) {
      googleAanalyticsEventSelectContent('select_content',product,this.categoryId)
      this.$router.push({
        name: productName,
         query: {
            categoryId: this.categoryId,
          }
      });
    },
    scrollToTop() {
     const data=  this.$refs.divOne
      if(data){
        this.$smoothScroll({
        scrollTo: data.$el.scrollTop,
        duration: 300,
        easingFunction: "easeInOutCubic",
        updateHistory: false
      })
      }
    },
    pageViewChange() {

      this.$emit("criteriaChanged")
       googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
       this.scrollToTop()
    },
    pageSizeWiseProduct() {
      this.updatePageNumber();
      this.$emit("criteriaChanged");
        googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
          this.scrollToTop()
    },
    sortAndNextPage() {
     this.$emit("criteriaChanged");
     googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
    },

    updatePageNumber() {
      if (this.pageNumber * Number(this.pageSize) > this.totalProducts) {
        let quotient = Math.floor(this.totalProducts / Number(this.pageSize));
        let remainder = this.totalProducts % Number(this.pageSize);
        this.pageNumber = quotient + (remainder == 0 ? 0 : 1);
      } else {
      
      }
    },
    roundUp(num) {
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals;
    }
  },
  created() {
   this.searchResultsNew=JSON.parse(JSON.stringify(this.searchResults));
     if(this.searchTerms)
     {
        googleAanalyticsEventCategoryPage(this.searchResults.page,this.categoryId,this.categoryListIdWise)
          }
    if (this.totalProducts)
      this.totalVisiblePages = Math.ceil(
        this.totalProducts / Number(this.pageSize)
      );
  }
};
</script>
<style lang="scss" >
.sort-button.theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: transparent !important;
}
.categorybaseproducts-pagination-button .v-pagination__item--is-active .v-btn {
  outline: #ffffff !important;
  color:#fff;
  background-color:#2850da;
  opacity: 1;
}
.categorybaseproducts-pagination-button .v-pagination__item {
  outline: #ffffff !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px !important;
}
.categorybaseproducts-pagination-button ,.categorybaseproducts-pagination-button .v-pagination__list{
  justify-content: flex-start ;
}
.categorybaseproducts-overflow-button .v-input__control{
  border-left: 1px solid #dcdddd ;
  border-right: 1px solid #dcdddd ;
  max-width: 85px ;
  max-height: 36px ;
}
.categorybaseproducts-overflow-button .v-input__control{
  border-top: 2px solid #dcdddd ;
  border-bottom: 2px solid #dcdddd ;
}
.categorybaseproducts-overflow-button {
  max-width: 85px ;
}
.categorybaseproducts-overflow-button .v-field__input,.categorybaseproducts-overflow-button .v-field__append-inner {
  padding-top: 5px;
}
 
.categorybaseproducts-overflow-button .v-field__outline {
  display: none;
}
.categorybaseproducts-pagination-button
  .v-pagination__navigation.v-pagination__navigation--disabled {
  display: none;
}
.categorybaseproducts-pagination-button
  .v-pagination__navigation
  .v-icon.v-icon {
  font-style: normal !important;
  color: #1d1d1d !important;
  text-transform: uppercase !important;
  font-size: 14px;
  font-family: "Whyte-Regular", sans-serif;
  letter-spacing: -0.1px;
}
.categorybaseproducts-select-button .v-input__control .v-input__slot {
  border: thin solid #8b8b8b !important;
}
.categorybaseproducts-select-button:hover .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.categorybaseproducts-select-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}
.categorybaseproducts-select-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-select-button:hover
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: #2850da !important;
}
.categorybaseproducts-pagination-button .v-pagination__navigation {
  outline: #ffffff !important;
  box-shadow: none !important;
  padding: 0px !important;
 
}
.custom-pagination li:nth-child(1) {
  margin-right: 15px !important;
  margin-left: 15px;
}
.categorybaseproducts-select-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.categorybaseproducts-sortby-button .v-select__selection .v-select__selection-text,.categorybaseproducts-filter-button .v-select__selection .v-select__selection-text{
  color: #2850da !important;
}
.categorybaseproducts-sortby-button .v-field__outline,.categorybaseproducts-filter-button .v-field__outline{
  display: none;
}
.categorybaseproducts-sortby-button .v-text-field__details,.categorybaseproducts-filter-button .v-text-field__details{
  display: none;
}
.categorybaseproducts-sortby-button .v-input__control,.categorybaseproducts-filter-button .v-input__control{
  border: thin solid #2850da !important;
}
.categorybaseproducts-sortby-button:hover .v-input__control .v-input__slot,.categorybaseproducts-filter-button:hover .v-input__control .v-input__slot{
  border: thin solid #2850da !important;
}
.categorybaseproducts-sortby-button.v-input--is-label-active.v-input--is-focused,.categorybaseproducts-filter-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}
.categorybaseproducts-sortby-button:hover
  .v-select__selection.v-select__selection--comma ,.categorybaseproducts-filter-button:hover
  .v-select__selection.v-select__selection--comma{
  color: #2850da !important;
}
.categorybaseproducts-sortby-button:hover
  .v-input__append-inner
  .v-input__icon
  .v-icon ,.categorybaseproducts-filter-button:hover
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: #2850da !important;
}
.categorybaseproducts-sortby-button
  .v-field__append-inner
  .v-icon ,.categorybaseproducts-filter-button  .v-field__append-inner
  .v-icon ,.categorybaseproducts-filter-button  .v-field__append-inner
  .v-icon,.categorybaseproducts-filter-button  .v-field__field{
  color: #2850da !important;
}
.categorybaseproducts-sortby-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma ,.categorybaseproducts-filter-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma{
  color: #2850da !important;
}
.categorybaseproducts-sortby-button.v-input--is-label-active.v-input--is-focused.v-text-field.v-text-field--solo
  .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  -ms-flex-item-align: center;
  align-self: center;
  padding-top: 5px;
  border-left: 1px solid #2850da;
  padding-left: 10px;
  height: 34px;
}
.categorybaseproducts-sortby-button.v-select--active-menu .v-input__control .v-field__append-inner{
  -ms-flex-item-align: center;
  align-self: center;
  padding-top: 5px;
  border-left: 1px solid #2850da;
  padding-left: 10px;
  height: 34px;
}
.categorybaseproducts-sortby-button.v-select--active-menu .mdi-plus::before{
  content: "\F0374" !important;
}
.searchproducts-cancel-buttons.v-icon.v-icon::after {
  opacity: 0 !important;
}
.categorybaseproducts-sortby-button .v-text-field__details {
  display: none;
}
.categorybaseproducts-overflow-button .v-text-field__details {
  display: none;
}
.categorybaseproducts-filter-button .v-text-field__details {
  display: none;
}
.categorybaseproducts-overflow-button .v-input__details{
  display:none;
}
.filter-component-width .v-expansion-panels {
  display: inline-block;
}
.categorybaseproducts-pagination-button.v-pagination__item.v-pagination__item--is-active.v-btn {
  background-color:#2850da ;
}
.categorybaseproducts-pagination-button
  .v-btn__content {
  font-size: 16px;
  font-weight: 400;
}
.search-product-circle-border, .search-color-circle-border  {
    border: 2px solid #2850da!important;
}

.search-product-color-circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid #777;
}
.search-color-circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
}
</style>
<style src="./SearchProducts.scss" lang="scss" scoped />