import { authHeader } from './auth';
import { wrapRequest } from './nav';

const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;



export const doFetchSavedForLaterList = wrapRequest((customerId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',

        }
    };
    return fetch(`${imageLibApiUrl}/builder_session?user_data->customer_id=eq.${customerId}`, requestOptions)
});

export const doAddToSavedForLater = wrapRequest(({ sku }) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(
            {
                "wishlistItem":
                {
                    "sku": sku
                }
            }
        )
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/wishlist/add`, requestOptions);
});

export const doDeleteWishlistProduct = ((itemId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        },
    };
    return fetch(`${imageLibApiUrl}/builder_session?id=eq.${itemId}`, requestOptions)
})

