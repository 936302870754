<template>
  <div>
    <v-row class="seofootercontent-image-background ma-0" v-if="getSeoBlock()">
      <v-container class="px-lg-12 pb-md-0 px-6 mt-0 mb-4">
        <v-row>
          <v-col class="regular-font pr-lg-8 mt-0 pt-0 mb-0">
            <div v-html="getSeoBlock()"></div>
          </v-col>
        </v-row>
      </v-container>
     
    </v-row>
     
  </div>
</template>
<script>
import image18 from '@/assets/Image 18.png'
import image19 from '@/assets/Image 19.png'
import image20 from '@/assets/Image 20.png'
export default {
  name: "SeoFooterContent", 
  components: {},
  computed: {
   
    categoryList: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    categoryId: function () {
      return this.$route.meta.id;
    },
    categorySeoContent: function () {
      return this.$store.state.layout.cmsBlockContent;
    },
  },
  data: () => ({
    image18:image18,
    image19:image19,
    image20: image20, 
  }),
  watch: {
    categoryId() {
      this.getSeoBlock();
    },
    categoryList() {
      this.getSeoBlock();
    },
  },
  methods: {
    getSeoBlock() {
      if (this.categoryList) {
        let attributes = this.categoryList.filter(
          (data) => data.id == this.categoryId
        );
        if (attributes.length > 0) {
          let code = attributes[0].ca.filter(
            (data) => data.ac == "landing_page"
          );
          if (code.length > 0) {
            let cId = parseInt(code[0].v);
            if (this.categorySeoContent.length) {
              let data = this.categorySeoContent.filter((element) => {
                return element.id === cId;
              });
              if (data.length > 0) {
                return data[0].content;
              }
            }
          }
        }
      }
    },
  },
  async created() {
    if (this.categoryId) {
      await this.getSeoBlock();
    }
  },
};
</script>
<style src="./SeoFooterContent.scss" lang="scss" scoped /> 