import { authHeader } from './auth';
import { wrapRequest, meWrapRequest } from './nav';

const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
 const retailorId = import.meta.env.VITE_RETAILOR_ID;
const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;
const assetNodeslsUrl = import.meta.env.VITE_ASSETS_NODESLS_URL;
const zinreloApiKeyIdentifier = import.meta.env.VITE_ZINRELO_KEY_INDENTIFIER;
const assetNodeslsNewUrl = import.meta.env.VITE_ASSETS_NODESLS_NEW_URL;

export const doValidateAddress = wrapRequest((address) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "address": {
                "countryId": address.countryId,
                "regionId": address.regionId,
                "region": address.region ? address.region.region : "",
                "street": [address.streetNoOne, address.streetNoTwo],
                "company": address.company ? address.company : "",
                "telephone": address.telephone,
                "postcode": address.postcode,
                "city": address.city,
                "firstname": address.firstname,
                "lastname": address.lastname,
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/default/V1/carts/mine/validate-address`, requestOptions);
});
export const doValidateAddressCheckout = wrapRequest((address, stateName) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "address": {
                "countryId": address.countryId,
                "regionId": address.regionId,
                "region": stateName,
                "street": [address.streetNoOne, address.streetNoTwo],
                "telephone": address.telephone,
                "postcode": address.postcode,
                "city": address.city,
                "firstname": address.firstname,
                "lastname": address.lastname,
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/default/V1/shipping-information/validate-address`, requestOptions);
});
export const doFetchStates = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/directory/countries/US`, requestOptions)

});
export const doFetchCountries = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        }
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V1/directory/countries`, requestOptions)

});
export const doFetchBasicInfo = meWrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});

export const doFetchChangePassword = async (currentPassword, newPassword) => {

    console.log("currentPassword: ", currentPassword)
    console.log("newPassword : ", newPassword)

    return false;
};
export const doUpdateAddress = meWrapRequest((one, address) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "customer": {
                "firstname": one.firstname,
                "lastname": one.lastname,
                "email": one.email,
                "website_id": one.websiteId,
                "addresses": [{
                    "region_id": address.regionId,
                    "country_id": address.countryId,
                    "street": [
                        address.streetNoOne, address.streetNoTwo
                    ],
                    "postcode": address.postcode,
                    "city": address.city,
                    "telephone": address.telephone,
                    "firstname": address.firstname,
                    "lastname": address.lastname
                }],
                "default_shipping": one.defaultShipping,
                "default_billing": one.defaultBilling
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});
export const doUpdateUserAddress = meWrapRequest((one, addresses, customerNumber) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "customer": {
                "firstname": one.firstname,
                "lastname": one.lastname,
                "email": one.email,
                "website_id": one.websiteId,
                "addresses": addresses,
                "default_shipping": one.defaultShipping,
                "default_billing": one.defaultBilling,
                "custom_attributes": [
                    {
                        "attribute_code": "customer_number",
                        "value": customerNumber
                    }]
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});
export const doUpdateSubscription = meWrapRequest((one, customerNumber) => {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "customer": {
                "firstname": one.firstname,
                "lastname": one.lastname,
                "email": one.email,
                "website_id": one.websiteId,
                "custom_attributes": [
                    {
                        "attribute_code": "customer_number",
                        "value": customerNumber
                    }],
                "extension_attributes": {
                    "is_subscribed": one.extensionAttributes.isSubscribed
                }
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});
export const doChangeBasicInfo = meWrapRequest((customer) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            customer
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});
export const doChangePassword = meWrapRequest((currentPassword, newPassword) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "currentPassword": currentPassword,
            "newPassword": newPassword
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me/password`, requestOptions)
});
export const doFetchEstimatedShippingMethods = wrapRequest((one, address) => {
    let streetAddress = [];
    let streetArray = [];
    if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
        streetAddress[0] = address.streetNoOne;
        streetArray = streetAddress.filter(v => v != '');
    } else {
        streetAddress[0] = address.streetNoOne
        streetAddress[1] = address.streetNoTwo;
        streetArray = streetAddress.filter(v => v != '');
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({

            "address": {
                "region": address.region.region,
                "region_id": address.regionId,
                "region_code": address.regionCode,
                "country_id": address.countryId,
                "street": streetArray,
                "postcode": address.postcode,
                "city": address.city,
                "firstname": address.firstname,
                "lastname": address.lastname,
                "customer_id": one.id,
                "email": one.email,
                "telephone": address.telephone,
                "same_as_billing": 1
            }
        }),

    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/estimate-shipping-methods`, requestOptions)
});
export const doShippingInformation = wrapRequest((one, address, estimated, suggestedAddress) => {
    let streetAddress = [];
    let streetArray = [];
    if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
        streetAddress[0] = suggestedAddress.streetNoOne ? suggestedAddress.streetNoOne : address.streetNoOne;
        streetArray = streetAddress.filter(v => v != '');
    } else {
        streetAddress[0] = suggestedAddress.streetNoOne ? suggestedAddress.streetNoOne : address.streetNoOne
        streetAddress[1] = suggestedAddress.streetNoTwo ? suggestedAddress.streetNoTwo : address.streetNoTwo;
        streetArray = streetAddress.filter(v => v != '');
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "addressInformation": {
                "shipping_address": {
                    "region_id": suggestedAddress.region_id ? suggestedAddress.region_id : address.regionId,
                    "country_id": suggestedAddress.country_id ? suggestedAddress.country_id : address.countryId,
                    "street": streetArray,
                    "postcode": suggestedAddress.postcode ? suggestedAddress.postcode : address.postcode,
                    "city": suggestedAddress.city ? suggestedAddress.city : address.city,
                    "firstname": address.firstname,
                    "lastname": address.lastname,
                    "customer_id": one.id,
                    "email": one.email,
                    "telephone": address.telephone
                },
                "shipping_carrier_code": estimated.carrierCode,
                "shipping_method_code": estimated.methodCode
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/shipping-information`, requestOptions)
});
export const doDeleteAddress = wrapRequest((addressId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/addresses/${addressId}`, requestOptions)
});

export const doProductsOrder = meWrapRequest((userQuote, paymentMethod, oneAddress) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "cartId": userQuote,
            "billingAddress": oneAddress,
            "paymentMethod": paymentMethod,
            "comments": ""
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/payment-information`, requestOptions)
});

export const doProductsOrderOnCC = meWrapRequest((orderNumber, shippingAddress, webOrderNumber, estimatedShipping) => {
    let address2 = shippingAddress.street[1] ? escape(shippingAddress.street[1]) : undefined;
    let method = 'bes';
    if (estimatedShipping) {
        method = estimatedShipping.methodCode.substring(0, 3);
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "retailorId": retailorId,
            "orderNumber": orderNumber,
            "shipToFirstName": escape(shippingAddress.firstname),
            "shipToLastName": escape(shippingAddress.lastname),
            "shipToAddress1": escape(shippingAddress.street[0]),
            "shipToAddress2": address2,
            "shipToCity": shippingAddress.city,
            "shipToState": shippingAddress.regionCode,
            "shipToZip": shippingAddress.postcode,
            "shipToCountry": shippingAddress.countryId,
            "shipToPhone": shippingAddress.telephone,
            "shipToEmail": shippingAddress.email,
            "shippingMethod": method,
            "webOrderNumber": webOrderNumber
        })
    };

    return fetch(`${vueAppNodeslsApiUrl}/cc-submit-order`, requestOptions)
});


export const doFetchProductPdfs = meWrapRequest((orderNumber, itemId) => {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${assetNodeslsUrl}/order-builder-2?orderId=${orderNumber}&itemId=${itemId}`, requestOptions)
});

export const doZinreloToken = meWrapRequest((basicInfo) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(
            {
                "user_info": {
                    'sub': zinreloApiKeyIdentifier,
                    'member_id': basicInfo.email,
                    'email_address': basicInfo.email,

                    'first_name': basicInfo.firstname,
                    'last_name': basicInfo.lastname,
                    'member_status': 'active',

                    'address': {
                        'line1': basicInfo.addresses && basicInfo.addresses.length && basicInfo.addresses[0].street && basicInfo.addresses[0].street.length ? basicInfo.addresses[0].street[0] : '',
                        'line2': basicInfo.addresses && basicInfo.addresses.length && basicInfo.addresses[0].street && basicInfo.addresses[0].street.length ? basicInfo.addresses[0].street[1] : '',
                        'city': basicInfo.addresses && basicInfo.addresses.length ? basicInfo.addresses[0].city : '',
                        'state': basicInfo.addresses && basicInfo.addresses.length && basicInfo.addresses[0].region ? basicInfo.addresses[0].region.regionCode : '',
                        'country': basicInfo.addresses && basicInfo.addresses.length ? basicInfo.addresses[0].countryId : '',
                        'postal_code': basicInfo.addresses && basicInfo.addresses.length ? basicInfo.addresses[0].postcode : '',
                    }
                }
            })
    };
    return fetch(`${assetNodeslsNewUrl}/zinrelo_token`, requestOptions)
});

export const dosetFingerprintWithUserId = wrapRequest((basicData) => {
     
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    };
    return fetch(`${assetNodeslsNewUrl}/fingerprint-update-request?requestId=${basicData.requestId}&linkedId=${basicData.userId}`, requestOptions);
});