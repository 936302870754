import {
  getDomain,
  getCookieByName,
  removeCookieByName,
  authHeader,
} from "@/services/auth";
import { useCookies } from "vue3-cookies";
import { doPostIncident } from "@/services/incident.service";
const { cookies } = useCookies();
const vueOrgId = import.meta.env.VITE_APP_ORGANIZATION_ID;
import { detectIncognito } from "detectincognitojs";

export const pricerFiles = {
  DieTypeProcessXRef: [],
  ItemPriceBase: [],
  ItemPriceException: [],
  ItemXRef: [],
  LinerPricingCategoryMaster: [],
  OccasionUseXRef: [],
  PriceSublineFlatXRef: [],
  PriceSublineQuantityXRef: [],
  PriceSublineTypeMaster: [],
  PricingSublineProcessHierarchyXRef: [],
  ProcessMaster: [],
  RateMaster: [],
  RateQuantity: [],
  RibbonTyingStyleMaster: [],
  SuppliedMaterialDetail: [],
  SurchargeMaster: [],
  TextUpgradeMaster: [],
};
export const pricerFieldsIdx = {
  ProcessMaster: {
    ProcessID: 1,
    ProcessAvailabilityStart: 2,
    ProcessAvailabilityEnd: 3,
    ProcessIsActive: 4,
    TextUpgradeOverride: 5,
    AddlPressRunNoDie: 6,
    AddlPressRunWithDie: 7,
  },
  RateQuantity: {
    ProcessID: 1,
    RateID: 2,
    RateQuantityHigh: 3,
    RateQuantityLow: 4,
    RateQuantityAvailabilityStart: 5,
    RateQuantityAvailabilityEnd: 6,
    RateQuantityIsActive: 7,
    RateAmount: 8,
    RateQuantityID: 9,
  },
  RateMaster: {
    RateBasedAPR: 1,
    RateID: 2,
    RateAvailabilityStart: 3,
    RateAvailabilityEnd: 4,
    APRCalculation: 5,
  },
  DieTypeProcessXRef: {
    ProcessID: 1,
    DieTypeID: 2,
    DieTypePrice: 3,
    DieChargePerLine: 4,
  },
  ItemPriceBase: {
    JDEShortItemNumber: 1,
    PriceBasePer25: 2,
    ItemPriceBaseAvailabilityStart: 3,
    ItemPriceBaseAvailabilityEnd: 4,
    ItemPriceBaseIsActive: 5,
  },
  ItemPriceException: {
    OrderedItemNumber: 1,
    PriceExceptionPer25: 2,
    ItemPriceExceptionAvailabilityStart: 3,
    ItemPriceExceptionAvailabilityEnd: 4,
    ItemPriceExceptionIsActive: 5,
  },
  TextUpgradeMaster: {
    TextUpGradeID: 1,
    TextUpgradePrice: 2,
    TextUpGradeAvailabilityStart: 3,
    TextUpGradeAvailabilityEnd: 4,
    TextUpGradeIsActive: 5,
  },
  LinerPricingCategoryMaster: {
    LinerPricingCategoryShortDesc: 1,
    LinerPricingCategoryPrice: 2,
    LinerPricingCategoryAvailabilityStart: 3,
    LinerPricingCategoryAvailabilityEnd: 4,
    LinerPricingCategoryIsActive: 5,
  },
  ItemXRef: {
    OrderedItemNumber: 1,
    ItemID: 2,
    ItemAvailabilityStart: 3,
    ItemAvailabilityEnd: 4,
    ItemIsActive: 5,
    JDEShortItemNumber: 6,
    PriceGroup: 7,
  },
  PriceSublineQuantityXRef: {
    IsActive: 1,
    QuantityPrice: 2,
    QuantityValue: 3,
    AvailabilityEnd: 4,
    MinimumQuantity: 5,
    AvailabilityStart: 6,
    SublineQuantityId: 7,
    PriceSublineTypeId: 8,
  },
  SuppliedMaterialDetail: {
    SuppliedMaterialID: 1,
    SuppliedMaterialAvailabilityStart: 2,
    SuppliedMaterialAvailabilityEnd: 3,
    SuppliedMaterialIsActive: 4,
    SuppliedMaterialTypeID: 5,
  },
  SurchargeMaster: {
    SurchargeShortDesc: 1,
    SurchargeAvailabilityStart: 2,
    SurchargeAvailabilityEnd: 3,
    SurchargeIsActive: 4,
    SurchargeID: 5,
    SurchargeTypeID: 6,
    SurchargeDescription: 7,
  },
  PricingSublineProcessHierarchyXRef: {
    ProcessId: 1,
    SublineTypeId: 2,
    AvailabilityStart: 3,
    AvailabilityEnd: 4,
    IsActive: 5,
    HierarchyOrder: 6,
  },
  PriceSublineTypeMaster: {
    IsActive: 1,
    SublineTypeId: 2,
    AvailabilityEnd: 3,
    AvailabilityStart: 4,
    PriceSublineTypeId: 5,
    SublineTypeShortDesc: 6,
    CorrespondingPriceSurchargeTypeId: 7,
  },
  PriceSublineFlatXRef: {
    Price: 1,
    IsActive: 2,
    SublineFlatId: 3,
    AvailabilityEnd: 4,
    AvailabilityStart: 5,
    PriceSublineTypeId: 6,
  },
  RibbonTyingStyleMaster: {
    ReplicationID: 1,
    IsDrillingRequired: 2,
    RibbonTyingStyleID: 3,
    RibbonTyingStylePrice: 4,
    RibbonTyingStyleIsActive: 5,
    RibbonTyingStylePDSValue: 6,
    RibbonTyingStyleShortDesc: 7,
    RibbonTyingStyleDescription: 8,
    RibbonTyingStyleAvailabilityEnd: 9,
    RibbonTyingStyleAvailabilityStart: 10,
    RibbonTyingStyleMasterLegacyValue: 11,
  },
  OccasionUseXRef: {
    OccasionID: 1,
    UseID: 2,
    RateID: 3,
    TextUpgradeID: 4,
    DieTypeID: 5,
  },
};

export const doFormatAddress = (address) => {
  let newaddress =
    address.streetNoOne +
    " " +
    address.city +
    " " +
    address.regionId +
    " " +
    address.countryId;
  return newaddress;
};
export const fetchStates = (countryId, countryWiseStates) => {
  let oneCountry = countryWiseStates.filter((states) => {
    return states.id == countryId;
  });
  if (oneCountry.length > 0 && oneCountry[0].availableRegions) {
    let allStates = oneCountry[0].availableRegions.map((val) => {
      return {
        value: val.id,
        label: val.name,
      };
    });
    return allStates;
  } else {
    return [{ value: "", label: "No States Found" }];
  }
};

export const attribute = (
  customAttributes,
  attributeCode,
  productAttributes
) => {
  if (productAttributes.items && customAttributes) {
    let result = customAttributes.filter((element) => {
      return element.attributeCode === attributeCode;
    });
    if (result.length > 0) {
      let val = result[0].value; //254 //uom
      let newValue = productAttributes.items.filter((elements) => {
        return elements.attributeCode === attributeCode;
      });
      let filter = newValue[0].options.filter((elementNew) => {
        return elementNew.value === val;
      });
      if (filter.length > 0) {
        return filter[0].label;
      }
    }
  }
};

export const customAttributeValueNew = (customAttributes, attributeCode) => {
  let result = customAttributes.filter((element) => {
    return element.ac === attributeCode;
  });
  if (result[0]) return result[0].v;
  else return "";
};
export const customAttributeValue = (customAttributes, attributeCode) => {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].value;
  else return "";
};

export const getLable = (customAttributes, attributeCode) => {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].label;
  else return "";
};

export const getMeta = (data) => {
  let metaObj = {};
  if (data) {
    data.filter((element) => {
      if (element.attributeCode === "meta_title" || element.ac === "meta_title")
        metaObj.title = element.value
          ? element.value
          : element.v
            ? element.v
            : null;
      if (
        element.attributeCode === "meta_description" ||
        element.ac === "meta_description"
      )
        metaObj.description = element.value
          ? element.value
          : element.v
            ? element.v
            : null;
      if (
        element.attributeCode === "meta_keywords" ||
        element.ac === "meta_keywords"
      )
        metaObj.keywords = element.value
          ? element.value
          : element.v
            ? element.v
            : null;
      if (
        element.attributeCode === "meta_keyword" ||
        element.ac === "meta_keyword"
      )
        metaObj.keywords = element.value
          ? element.value
          : element.v
            ? element.v
            : null;
    });
  }

  return metaObj;
};
export const getCategoryOne = (list, categoryId) => {
  let result = list.filter((element) => {
    return element.id === categoryId;
  });
  if (result[0]) return result[0];
  else return "";
};
export const commonValidationRules = {
  noRules: [],
  passwordRule: [
    (v) => !!v || "Password is required",
    (v) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
        v
      ) ||
      "Password must contain atleast one lowercase, one uppercase, one digit and one special character",
  ],
};
export const goToCategoryPage = (
  vm,
  category,
  subCategory,
  subChlid,
  categoryId
) => {
  vm.$router
    .push({
      name: "CategoryPage",
      params: {
        category: category,
        subCategory: subCategory,
        subChlid: subChlid,
      },
      query: {
        pageNumber: 1,
        pageSize: "9",
        sortBy: "position",
        filter: "e30=",
      },
      meta: {
        id: categoryId,
      },
    })
    .catch((error) => {
      error;
    });
};
export const goToCategoryPageRouting = (vm, category) => {
  vm.$router
    .push({
      name: category.n + "_" + category.id,

    })
    .catch((error) => {
      error;
    });
};

function getlabelValue(customAttributes, attributeCode) {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].label;
  else return "";
}

function getlabelValuePurchase(customAttributes) {
  try {
    let label = JSON.parse(customAttributes);
    if (label) return label.label;
    else return "";
  } catch (error) {
    console.log("error", error);
    return "";
  }
}
export const getCategoriesPurchase = (product) => {
  try {
    let categoryLink = product.extensionAttributes.categoryLinks;
    for (let i = 0; i < 2; i++) {
      let newVal = JSON.parse(categoryLink[i]);
      if (newVal) {
        return newVal.category_name;
      }
    }
  } catch (error) {
    console.log("error", error);
    return "";
  }

  return "";
};
export const createCagetoryChildParents = async (categoryListIdWise, catId) => {
  let newArray = [];
  let newObj = await getParentId(categoryListIdWise, catId);
  if (newObj.length > 0) {
    let obj = {
      text: newObj[0].n,
    };
    newArray.push(obj);
    while (newObj[0].pid != 2) {
      newObj = await getParentId(categoryListIdWise, newObj[0].pid);
      obj = {
        text: newObj[0].n,
      };
      newArray.push(obj);
    }
  }

  let actualArray = newArray.reverse();
  var catObj = {};
  for (let i = 0; actualArray.length > i; i++) {
    if (i === 0) {
      catObj["item_category"] = actualArray[i].text;
    } else {
      catObj["item_category" + i] = actualArray[i].text;
    }
  }

  return catObj;
};
export const getParentId = async (categoryListIdWise, categoryId) => {
  return categoryListIdWise.filter((element) => {
    return element.id === categoryId;
  });
};
export const googleAanalytics = async (
  vm,
  res,
  productDetails,
  qtyNew,
  action,
  name,
  categoryListIdWise
) => {
  setTimeout(async () => {
    let catName = null;
    let childParentsObj = {};
    let catId = 0;
    if (getCookieByName(res.item_id)) {
      catName = getCookieByName(res.item_id);
      catId = catName.split(":")[1];
      catName = catName.split(":")[0];
      childParentsObj = await createCagetoryChildParents(
        categoryListIdWise,
        Number(catId)
      );
      localStorage.setItem(
        "quote_item_id" + res.item_id,
        JSON.stringify(childParentsObj)
      );
    } else if (productDetails.extensionAttribute.catagoryLinks[0]) {
      catName = productDetails.extensionAttribute.catagoryLinks[0].categoryName;
      childParentsObj = { item_category: catName };
      localStorage.setItem(
        "quote_item_id" + res.item_id,
        JSON.stringify(childParentsObj)
      );
    }
    let personalizeType = "";
    let nature = "";
    let productType = await customAttributeValue(
      productDetails.customAttribute,
      "is_personalize"
    );
    if (productType != "0") {
      nature = "personalized";
      if (
        customAttributeValue(
          productDetails.customAttribute,
          "builder_version"
        ) === "1.0" ||
        customAttributeValue(
          productDetails.customAttribute,
          "builder_version"
        ) === ""
      ) {
        personalizeType = "crane_connection";
      } else {
        personalizeType = "builder";
      }
    } else {
      nature = "boxed";
      personalizeType = null;
    }

    let gtag = window.gtag ? window.gtag : null;
    if (gtag) {

    }

    console.log("google analytics called ", action, name);

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: action,
      ecommerce: {
        nature: nature,
        personality: personalizeType,
        currency: "USD",
        value: res.price * qtyNew,
        items: [
          {
            item_id: productDetails.sku,
            item_name: productDetails.name,
            item_brand: getlabelValue(productDetails.customAttribute, "brand"),
            item_category: catName,
            price: res.price,
            quantity: qtyNew,
            ...childParentsObj,
          },
        ],
      },
    });
    console.log("itemGA4 List add_to_cart", {
      currency: "USD",
      value: res.price * qtyNew,
      item_id: productDetails.sku,
      item_name: productDetails.name,
      item_brand: getlabelValue(productDetails.customAttribute, "brand"),
      item_category: catName,
      price: res.price,
      quantity: qtyNew,
      ...childParentsObj,
    });
    console.log("GA4 NEW CODE CALLED", action);

  }, 500);
};
export const googleAanalyticsEventRemoveCart = (action, productDetails) => {
  let gtag = window.gtag ? window.gtag : null;
  if (gtag) {

  }
  console.log("google analytics called ", action);

  /* GA4 NEW CODE */
  window.dataLayer.push({ ecommerce: null });
  localStorage.removeItem("quote_item_id" + productDetails.itemId);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      currency: "USD",
      value: Number(productDetails.price) * productDetails.qty,
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          price: Number(productDetails.price),
          quantity: productDetails.qty,
        },
      ],
    },
  });
  console.log("GA4 NEW CODE CALLED Remove Cart", {
    event: action,
    ecommerce: {
      currency: "USD",
      value: Number(productDetails.price) * productDetails.qty,
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          price: Number(productDetails.price),
          quantity: productDetails.qty,
        },
      ],
    },
  });

};
export const googleAanalyticsEventSelectContent = async (
  action,
  productDetails,
  categoryId
) => {
  let category = "";
  let listName = "";
  if (categoryId) {
    listName = "Category Results";
    category = await getCatPath(categoryId);
  } else {
    listName = "Search Results";
    category = "Search";
  }
  let gtag = window.gtag ? window.gtag : null;
  //  if (gtag) {
  let item = [];
  let itemGA4 = [];
  let productPrice = "";
  if (productDetails.lp) {
    productPrice = productDetails.lp;
  } else {
    if (productDetails.sp) {
      productPrice = roundUp(productDetails.sp);
    } else if (productDetails.p != 0) {
      productPrice = productDetails.p;
    }
  }

  itemGA4.push({
    item_id: productDetails.sku,
    item_name: productDetails.n,
    item_brand: productDetails.b,
    item_category: category,
    item_variant: productDetails.dc,
    price: productPrice,
    quantity: 1,
  });

  if (item.length > 0) {

  }
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: itemGA4,
    },
  });
  console.log("GA4 NEW CODE CALLED", action);

  console.log("google analytics called ", action);
};
export const criteoForProductList = (productList) => {
  let productIds = [];
  if (productList.length) {
    for (let p = 0; p < productList.length; p++) {
      productIds.push(productList[p].id);
      if (p == 2) break;
    }
    window.criteo_q.push({ event: "viewList", item: productIds });
    console.log("view product list", productIds);

  }
};
export const criteoForCurrentProduct = (productId) => {
  if (productId) {
    window.criteo_q.push({ event: "viewItem", item: productId });
    console.log("view product", productId);
  }
};
export const criteoUpdateUserEmail = (email) => {
  window.criteo_q.push({ event: "setEmail", email: email });
  console.log("user email", email);
};
export const criteoViewBasket = (list) => {
  let items = [];
  for (let i = 0; i < list.length; i++) {
    let item = list[i];
    items.push({
      id: item.stockDetails.productId,
      price: item.price,
      quantity: item.qty,
    });
  }
  if (items.length > 0) {
    window.criteo_q.push({
      event: "viewBasket",
      item: items,
    });
  }

  console.log("view basket", items);
};
export const listrakCartList = (list) => {
  let items = [];
  for (let i = 0; i < list.length; i++) {
    let productDetails = list[i];
    if (window._ltk) {
      window._ltk.SCA.AddItemWithLinks(
        productDetails.sku,
        productDetails.qty,
        productDetails.price,
        productDetails.name,
        productDetails.customAttributes.image,
        productDetails.customAttributes.urlKey
      );
      window._ltk.SCA.Submit();
      console.log(
        "listrak  add to cart event called ",
        productDetails.sku,
        productDetails.qty,
        productDetails.price,
        productDetails.name,
        productDetails.customAttributes.image,
        productDetails.customAttributes.urlKey
      );
    }
  }

  console.log("view basket", items);
};
export const listrakCartListClear = () => {
  if (window._ltk) {
    window._ltk.SCA.ClearCart();
  }
  console.log("listrak Clear Cart called");
};
export const googleAanalyticsPurchase = (resp, incrementId) => {
  let items = [];
  let itemsGA4 = [];
  let customerId = resp.customerId;
  let discountAmount = resp.discountAmount;

  let purchasedProductList = [];
  for (let i = 0; i < resp.items.length; i++) {
    let item = resp.items[i];
    let catName = "";

    if (getCookieByName(item.quoteItemId)) {
      catName = getCookieByName(item.quoteItemId);
      catName = catName.split(":")[0];
      removeCookieByName(item.quoteItemId);
    } else if (item.extensionAttributes["categoryLinks"]) {
      try {
        let categoryLink = JSON.parse(
          item.extensionAttributes["categoryLinks"][0]
        );
        catName = categoryLink.category_name;
      } catch (error) {
        console.log("error", error);
      }
    }
    let itemCategory = JSON.parse(
      localStorage.getItem("quote_item_id" + item.quoteItemId)
    );

    items.push({
      id: item.sku,
      name: item.name,
      category: catName,
      brand: getlabelValuePurchase(item.extensionAttributes.customAttributes),
      price: item.price,
      quantity: item.qtyOrdered,
    });

    itemsGA4.push({
      item_id: item.sku,
      item_name: item.name,
      item_brand: getlabelValuePurchase(
        item.extensionAttributes.customAttributes
      ),
      price: item.price,
      quantity: item.qtyOrdered,
      ...itemCategory,
    });
    localStorage.removeItem("quote_item_id" + item.quoteItemId);
    purchasedProductList.push({
      id: item.productId,
      price: item.price,
      quantity: item.qtyOrdered,
    });
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: incrementId,
          affiliation: "",
          revenue: resp.baseSubtotal,
          tax: resp.taxAmount,
          shipping: resp.shippingInclTax,
          coupon: resp.couponCode ? resp.couponCode : "",
          customerId: customerId,
          discountAmount: discountAmount,
        },
        products: items,
      },
    },
  });
  console.log("google analytics called ", "purchase");

  window.dataLayer.push({
    event: "purchase_ga4",
    ecommerce_ga4: {
      transaction_id: incrementId,
      affiliation: "",
      value: resp.baseSubtotal,
      tax: resp.taxAmount,
      shipping: resp.shippingInclTax,
      coupon: resp.couponCode ? resp.couponCode : "",
      currency: "USD",
      items: itemsGA4,
    },
  });
  console.log("GA4 NEW CODE CALLED purchase_ga4", itemsGA4);

  window.criteo_q.push({
    event: "trackTransaction",
    id: incrementId,
    item: purchasedProductList,
  });

  console.log("criteo track transaction ", purchasedProductList);
  console.log("Track transaction id ", incrementId);

  if (window._ltk) {
    window._ltk.Order.SetCustomer(
      resp.customerEmail,
      resp.customerFirstname,
      resp.customerLastname
    );
    window._ltk.Order.OrderNumber = incrementId;
    window._ltk.Order.ItemTotal = resp.baseSubtotal;
    window._ltk.Order.ShippingTotal = resp.shippingInclTax;
    window._ltk.Order.TaxTotal = resp.baseTaxAmount;
    window._ltk.Order.HandlingTotal = "0.00";
    window._ltk.Order.OrderTotal = resp.baseGrandTotal;

    for (let i = 0; i < resp.items.length; i++) {
      let item = resp.items[i];
      window._ltk.Order.AddItem(item.sku, item.qtyOrdered, item.price); // one line per item ordered
    }
    window._ltk.Order.Submit();
    console.log("listrak purchased event called", resp);

    window._ltk.SCA.Update("email", resp.customerEmail);
    console.log("listrak email event called", resp.customerEmail);
    window._ltk.SCA.ClearCart();
  }
};
export const getCatPath = async (catId) => {
  let path = "";
  let categoryDetailsList = window.craneCatalog;
  let categoryOne = await getCategoryOne(
    categoryDetailsList.allCategoryDetails,
    Number(catId)
  );
  if (categoryOne) {
    path = categoryOne.n;
  }

  return path;
};

export const googleAanalyticsEventPDP = async (
  productDetails,
  action,
  categoryId,
  categoryListIdWise
) => {
  let catName = "";
  let childParentsObjPDP = {};
  if (categoryId) {
    catName = await getCatPath(categoryId);
    childParentsObjPDP = await createCagetoryChildParents(
      categoryListIdWise,
      Number(categoryId)
    );
  } else {
    if (productDetails.extensionAttribute.catagoryLinks[0]) {
      let catId = productDetails.extensionAttribute.catagoryLinks[0].catagoryId;
      catName = await getCatPath(catId);
      childParentsObjPDP = { item_category: catName };
    }
  }
  let personalizeType = "";
  let nature = "";
  let productType = await customAttributeValue(
    productDetails.customAttribute,
    "is_personalize"
  );
  if (productType != "0") {
    nature = "personalized";
    if (
      customAttributeValue(
        productDetails.customAttribute,
        "builder_version"
      ) === "1.0" ||
      customAttributeValue(
        productDetails.customAttribute,
        "builder_version"
      ) === ""
    ) {
      personalizeType = "crane_connection";
    } else {
      personalizeType = "builder";
    }
  } else {
    nature = "boxed";
    personalizeType = null;
  }
  console.log("childParentsObjPDP view_item", childParentsObjPDP);

  let gtag = window.gtag ? window.gtag : null;

  if (gtag) {

  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      nature: nature,
      personality: personalizeType,
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          item_brand: getlabelValue(productDetails.customAttribute, "brand"),
          personalize: personalizeType,
          ...childParentsObjPDP,
        },
      ],
    },
  });
  console.log("itemGA4 List view_item", {
    item_id: productDetails.sku,
    item_name: productDetails.name,
    item_brand: getlabelValue(productDetails.customAttribute, "brand"),
    personalize: personalizeType,
    ...childParentsObjPDP,
  });
  console.log("GA4 NEW CODE CALLED", action);

  console.log("google analytics event called ", action, categoryId, catName);
  if (window._ltk) {
    window._ltk.Activity.AddProductBrowse(productDetails.sku);
    console.log("listrak event called ", productDetails.sku);
  }
};

export const googleAanalyticsEventSaveForLatter = async (
  productDetails,
  action
) => {
  let personalizeType = "builder";
  let nature = "personalized";
  let gtag = window.gtag ? window.gtag : null;

  if (gtag) {

  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      nature: nature,
      personality: personalizeType,
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          item_brand: "crane",
          personalize: personalizeType,
        },
      ],
    },
  });
  console.log("GA4 NEW CODE CALLED", action);
};

export const listrakEmailCapture = (email) => {
  if (window._ltk) {
    window._ltk.SCA.Update("email", email);
    console.log("listrak email event called ", email);
  }
};
export const googleAanalyticsEvent = (action, category, productDetails) => {
  let gtag = window.gtag ? window.gtag : null;
  if (gtag) {

  }

  window.dataLayer.push({ ecommerce: null });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "personalize",
    personalize: {
      type: action,
      sku: productDetails.sku,
      name: productDetails.name,
    },
  });
  console.log("GA4 NEW CODE CALLED", "personalize");

  console.log("google analytics event called ", action);
};
export const googleAanalyticsCheckoutAddPaymentInfo = (action, options) => {
  console.table("options", options);
  let gtag = window.gtag ? window.gtag : null;

  let itemList = [];
  for (let i = 0; i < options.items.length; i++) {
    itemList.push({
      item_id: options.items[i].sku,
      item_name: options.items[i].name,

      price: options.items[i].price,
      currency: "USD",
      quantity: options.items[i].qtyOrdered,
    });
  }

  window.dataLayer.push({ ecommerce: null });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      currency: options.baseCurrencyCode,
      value: options.baseGrandTotal,
      coupon: options && options.couponCode ? options.couponCode : "",
      payment_type: options.payment.method,
      items: itemList,
    },
  });
  console.log("GA4 NEW CODE CALLED begin_checkout", action);

  console.log("google analytics called ", action);
};
export const googleAanalyticsCheckoutAddShippingInfo = (
  action,
  options,
  estimatedShipping
) => {
  let gtag = window.gtag ? window.gtag : null;
  let itemList = [];
  for (let i = 0; i < options.length; i++) {
    itemList.push({
      item_id: options[i].sku,
      item_name: options[i].name,
      price: options[i].price,
      currency: "USD",
      quantity: options[i].qty,
    });
  }

  if (estimatedShipping) {
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: action,
      ecommerce: {
        currency: "USD",
        value: estimatedShipping.amount,
        coupon: "",
        shipping_tier: estimatedShipping.methodTitle,
        items: itemList,
      },
    });
    console.log("GA4 NEW CODE CALLED", action);
  }


  console.log("google analytics called ", action);
};

export const googleAanalyticsEventCategoryPage = async (
  productDetails,
  categoryId,
  categoryListIdWise
) => {
  let gtag = window.gtag ? window.gtag : null;

  let category = "";
  let listName = "";
  let childParentsObj = "";

  if (categoryId) {
    listName = "Category Results";
    category = await getCatPath(categoryId);
    childParentsObj = await createCagetoryChildParents(
      categoryListIdWise,
      categoryId
    );
  } else {
    listName = "Search Results";
    category = "Search";
    childParentsObj = { item_category: "Search" };
  }
  console.log("childParentsObj view_item_list", childParentsObj);
  let itemList = [];
  let itemGA4 = [];
  for (let i = 0; i < productDetails.length; i++) {
    let productPrice = "";
    if (productDetails[i].lp) {
      productPrice = productDetails[i].lp;
    } else {
      if (productDetails[i].sp) {
        productPrice = roundUp(productDetails[i].sp);
      } else if (productDetails[i].p != 0) {
        productPrice = productDetails[i].p;
      }
    }
    itemGA4.push({
      item_id: productDetails[i].sku,
      item_name: productDetails[i].n,
      currency: "USD",
      index: i,
      item_brand: productDetails[i].b,

      item_variant: productDetails[i].dc,
      quantity: 1,
      price: productPrice,
      ...childParentsObj,
    });
  }
  console.log("itemGA4 List view_item_list", itemGA4);
  if (itemGA4.length > 0) {
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: itemGA4,
      },
    });
    console.log("GA4 NEW CODE CALLED", "view_item_list");
  }

  console.log("google analytics event category ", window.dataLayer);
};
export const googleAanalyticsCheckout = (action, options) => {
  let itemGA4 = [];
  let total = 0;
  for (let i = 0; i < options.items.length; i++) {
    let productInfo = options.items[i];
    total = total + productInfo.qty * Number(productInfo.price);
    itemGA4.push({
      item_id: productInfo.sku,
      item_name: productInfo.name,
      quantity: productInfo.qty,
      price: Number(productInfo.price),
    });
  }

  let gtag = window.gtag ? window.gtag : null;
  if (gtag) {
  }


  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    currency: "USD",
    value: total,
    ecommerce: {
      items: itemGA4,
    },
  });
  console.log("GA4 NEW CODE CALLED begin_checkout", {
    event: action,
    currency: "USD",
    value: total,
    ecommerce: {
      items: itemGA4,
    },
  });
  console.log("google analytics called ", action);
};
export const googleAanalyticsEventCategory = (
  action,
  category,
  categoryDetails
) => {
  let gtag = window.gtag ? window.gtag : null;
  if (gtag) {

  }
  console.log("google analytics event called ", {
    event_category: category,
    event_label: categoryDetails.name,
    value: categoryDetails.id,
  });

  window.dataLayer.push({ browse: null });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    browse: {
      id: categoryDetails.id,
      name: categoryDetails.name,
    },
  });
  console.log("GA4 NEW CODE CALLED", action);

};

export const listrakSubscribe = async (email) => {
  if (window._ltk) {
    window._ltk.Subscriber.List = "Footer";
    window._ltk.Subscriber.Email = email;
    window._ltk.Subscriber.Submit();
  }
};

export const checkEncodeURI = (str) => {
  try {
    decodeURIComponent(escape(window.atob(str)));
    return true;
  } catch (e) {
    return false;
  }
};
export const priceFormatter = (price, pdp) => {
  if (price != undefined) {
    price = price.toString();
    let decimals = price.split(".")[1];
    if (!Number(decimals) || isNaN(decimals) || decimals.length == 1)
      //if 2 or 2.1
      return Number(price).toFixed(2);
    if (decimals.length == 2 && pdp)
      //if 2 or 2.1
      return price;
    else if (decimals.length > 1)
      // if 2.34 or 2.56565
      return Number(price.match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
};

export const invalidPoBoxAddress = (address) => {
  let containedPoBox = false;
  let addressNew = address.toLowerCase();
  if (addressNew.search("po box") > -1) containedPoBox = true;
  else if (addressNew.search("pobox") > -1) containedPoBox = true;
  else if (addressNew.search("post office") > -1) containedPoBox = true;
  else if (addressNew.search("postoffice") > -1) containedPoBox = true;
  else if (addressNew.search("post office box") > -1) containedPoBox = true;
  else if (addressNew.search("postofficebox") > -1) containedPoBox = true;
  else if (addressNew.search("p.o.box") > -1) containedPoBox = true;
  else if (addressNew.search("p.o. box") > -1) containedPoBox = true;
  else if (addressNew.search("p. o. box") > -1) containedPoBox = true;
  else if (addressNew.search("p o box") > -1) containedPoBox = true;
  else containedPoBox = false;
  return containedPoBox;
};

export const getProductArray = (productData, categoryId) => {
  if (productData.allCategoryDetails) {
    let result = productData.allCategoryDetails.filter((element) => {
      return element.id === categoryId;
    });
    if (result[0]) return result[0].p;
    else return "";
  }
};
export const getProductsList = (productData, productArray) => {
  let productList = [];
  if (productData.products) {
    for (let i = 0; i < productArray.length; i++) {
      productData.products.filter((element) => {
        if (element.sku === productArray[i]) {
          productList.push(element);
        }
      });
    }
    return productList;
  }
};

export const checkPromotion = ({
  sku,
  quantity,
  product,
  productPartsObjects,
}) => {
  for (let i = 0; i < promotions.length; i++) {
    let promotion = promotions[i];
    let rules = promotion.promotion_rule;
    promotion.appliedRules = [];
    promotion.exclusive = [];
    promotion.exclusiveRules = [];
    for (let m = 0; m < rules.length; m++) {
      let rule = rules[m];
      let conditions = rule.conditions;
      rule.allCondition = false;

      if (rule.rule_type == "exclusive") promotion.exclusive.push("true");

      rule.evaluateConditions = [];
      for (let j = 0; j < conditions.length; j++) {
        let condition = conditions[j];
        switch (condition.variable) {
          case "product_sku":
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value.toLowerCase(),
              sku.toLowerCase()
            );
            break;
          case "product_tag":
            let tags = condition.value.split(",");
            if (product.tags) {
              const found = tags.some((r) => product.tags.indexOf(r) >= 0);
              if (found) rule.allCondition = true;
              else rule.allCondition = false;
            }
            break;

          case "quantity":
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value,
              quantity
            );

            break;

          case "Product_part_with_liner":
            let partName = condition.sub_variable;
            let objects = productPartsObjects.filter(
              (obj) => obj.partName.toLowerCase() == partName.toLowerCase()
            )[0];
            if (
              objects &&
              objects.linerObject &&
              objects.linerObject.selectedLiner
            ) {
              rule.allCondition = true;
            } else rule.allCondition = false;
            break;

          case "count_of_product_part_press_passes":
            let name = condition.sub_variable;
            let partObjects = productPartsObjects.filter(
              (obj) => obj.partName.toLowerCase() == name.toLowerCase()
            )[0];
            if (partObjects && partObjects.processes) {
              let processes = partObjects.processes;
              if (Object.keys(processes).length)
                rule.allCondition = evaluatePredicate(
                  condition.predicate,
                  condition.value,
                  processes[Object.keys(processes)[0]].passes
                );
              else
                rule.allCondition = evaluatePredicate(
                  condition.predicate,
                  condition.value,
                  0
                );
            } else rule.allCondition = false;
            break;
          case "count_of_product_press_passes":
            let passes = 0;
            let processesObject = {};
            for (let k = 0; k < productPartsObjects.length; k++) {
              let partObjects = productPartsObjects[k];
              if (partObjects.processes) {
                let processes = partObjects.processes;
                for (const [key, value] of Object.entries(processes)) {
                  if (!processesObject.hasOwnProperty(key)) {
                    processesObject[key] = [];
                  }
                  for (let p = 0; p < value.colors.length; p++) {
                    let color = value.colors[p];
                    if (!processesObject[key].includes(color)) {
                      passes = passes + 1;
                      processesObject[key].push(color);
                    }
                  }
                }
              }
            }
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value,
              passes
            );
            break;
        }
        rule.evaluateConditions.push(rule.allCondition);
      }
      if (
        rule.evaluateConditions.length > 0 &&
        !rule.evaluateConditions.includes(false)
      ) {
        promotion.appliedRules.push(rule);
        promotion.applied = true;
        if (rule.rule_type == "exclusive") {
          promotion.exclusiveRules.push("true");
        }
      }
    }
  }
  return promotions.filter((promotion) => {
    let startDate = promotion.start_dt;
    let endDate = promotion.end_dt;
    let currentDate = new Date();
    console.log(new Date(endDate).toJSON().slice(0, 10));
    if (
      new Date(endDate).getTime() > currentDate.getTime() &&
      new Date(startDate).getTime() <= currentDate.getTime()
    ) {
      return (
        promotion.applied === true &&
        promotion.exclusiveRules.length == promotion.exclusive.length
      );
    }
  })[0];
};

export const evaluatePredicate = (operator, compareValue, value) => {
  let boolean = true;
  switch (operator) {
    case ">":
      if (Number(value) > Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "<":
      if (Number(value) < Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "<=":
      if (Number(value) <= Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case ">=":
      if (Number(value) >= Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "==":
      if (Number(value) == Number(compareValue)) boolean = true;
      else boolean = false;
      break;

    case "=":
      if (Number(value) === Number(compareValue)) boolean = true;
      else boolean = false;
      break;

    case "IN":
      let values = compareValue.toLowerCase().split(",");
      if (values.includes(value.toLowerCase())) boolean = true;
      else boolean = false;
      break;
  }
  return boolean;
};

export const setCategoryNameInLocal = async (categoryId, res) => {
  let categoryDetailsList = window.craneCatalog;
  let categoryOne = await getCategoryOne(
    categoryDetailsList.allCategoryDetails,
    Number(categoryId)
  );
  if (categoryOne && res) {
    cookies.set(
      res.item_id,
      categoryOne.n + ":" + categoryId,
      "30d",
      null,
      getDomain(window.location.hostname)
    );
  }
  console.log("category name ", res.item_id, categoryOne.n);
};
export const getName = (label) => {
  const filterList = [
    { name: "brand", label: "b" },
    { name: "color_parent", label: "dc" },
    { name: "design_style", label: "ds" },
    { name: "occasion", label: "o" },
    { name: "paper_color_parent", label: "pc" },
    { name: "paper_weight", label: "pw" },
    { name: "personalized", label: "per" },
    { name: "photo_card", label: "ph" },
    { name: "printing_process", label: "pm" },
    { name: "product_def_type", label: "pt" },
    { name: "uom", label: "u" },
  ];
  let name = filterList.filter((item) => item.label == label);
  if (name) {
    return name[0].name;
  }
};
export const roundUp = (num) => {
  var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
};
export const colorsName = [
  { name: "Black", colorHex: "#000000" },
  { name: "Blue", colorHex: "#00339a" },
  { name: "Bordeaux", colorHex: "#612c2a" },
  { name: "Brown", colorHex: "#593a1d" },
  { name: "Dark Blue", colorHex: "#191971" },
  { name: "Deep Purple", colorHex: "#5c1f49" },
  { name: "Emerald", colorHex: "#00cc99" },
  { name: "Green", colorHex: "#12633c" },
  { name: "Grey", colorHex: "#646b63" },
  { name: "Light Blue", colorHex: "#87ceea" },
  { name: "Light Yellow", colorHex: "#ffff00" },
  { name: "Lime", colorHex: "#acff30" },
  { name: "Octane Green", colorHex: "#004516" },
  { name: "Orange", colorHex: "#ff6600" },
  { name: "Pink", colorHex: "#ff0180" },
  { name: "Plum", colorHex: "#592b2b" },
  { name: "Purple", colorHex: "#9c3d7c" },
  { name: "Red", colorHex: "#f80000" },
  { name: "Tan", colorHex: "#974c00" },
  { name: "Royal Blue", colorHex: "#00339a" },
  { name: "Turquoise", colorHex: "#26cdf7" },
  { name: "Wine", colorHex: "#7e3263" },
  { name: "Yellow", colorHex: "#febf00" },
  { name: "Water Green", colorHex: "#7db4af" },
  { name: "Fuchsia", colorHex: "#f51e89" },
  { name: "Viola", colorHex: "#ad3c87" },
  { name: "Bright Orange", colorHex: "#f76a0d" },
];

export const addPictureTagInHtmlCode = ({
  currentImageTag,
  variants,
  mediaImageUrl,
}) => {
  if (variants) {
    let modifiedSrc = currentImageTag.src
      .replace(window.location.href, "")
      .replace(window.location.origin, "")
      .replace(/%22%7D%7D/g, "")
      .replace(/%7B%7B/g, "")
      .replace(/%20url=%22/g, "")
      .replace("media", "");

    let fileExtention = modifiedSrc.split("/");
    let filename = fileExtention[fileExtention.length - 1].split(".")[0];
    let pictureElements = "";

    for (let iw = 0; iw < variants.length; iw++) {
      let imgWidth = variants[iw];
      pictureElements =
        pictureElements +
        `<source :key="${imgWidth}" media="(min-width:${imgWidth}px)" srcset="${mediaImageUrl}/${filename}_${imgWidth}.webp"  >`;
    }

    pictureElements =
      pictureElements +
      `<img class="${currentImageTag.className}" src="${mediaImageUrl}/${filename}.webp"    onerror="this.onerror=null;this.src='https://cdn.crane.com/stg-images/cc12011-1.webp';" loading="lazy">`;

    let pictureContent = null;
    if (currentImageTag.parentElement.hasChildNodes()) {
      let imgIndexHtml = currentImageTag.parentElement.innerHTML;
      let imgIndex = imgIndexHtml.indexOf("<img");
      let endImgtag = imgIndexHtml.indexOf(">");
      let sub = imgIndexHtml.substring(imgIndex, endImgtag + 1);
      let pictag = imgIndexHtml.replace(
        sub,
        `<picture style="width:100%;height: 100%;">${pictureElements}</picture>`
      );

      pictureContent = currentImageTag.parentElement.innerHTML = pictag;
    } else
      pictureContent = currentImageTag.parentElement.innerHTML = `<picture style="width:100%;height: 100%;">${pictureElements}</picture>`;
    return pictureContent;
  }
};

const toCase = (stringToCaseFn) => (value) => {
  const convertFn = toCase(stringToCaseFn);
  if (value === null) {
    return null;
  } else if (typeof value === "string") {
    return stringToCaseFn(value);
  } else if (value.length !== undefined) {
    return value.map(convertFn);
  } else if (typeof value === "object") {
    const object = {};
    Object.keys(value).forEach((key) => {
      let _value = value[key];
      if (_value && typeof _value === "object" && !checkForArray(value[key])) {
        _value = convertFn(value[key]);
      }
      object[convertFn(key)] = _value;
    });
    return object;
  } else {
    return value;
  }
};

const snakeStringToCamel = (str) =>
  str.replace(/ /g, "_").replace(/_(\w)/g, (m) => m[1].toUpperCase());
export const snakeToCamel = toCase(snakeStringToCamel);

const camelStringToSnake = (str) =>
  str.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
export const camelToSnake = toCase(camelStringToSnake);

function checkForArray(variable) {
  if (Array.isArray(variable)) {
    if (variable.length === 0) {
      return false;
    } else {
      for (let i = 0; i < variable.length; i++) {
        if (
          typeof variable[i] === "object" ||
          variable[i] === null ||
          Array.isArray(variable[i])
        ) {
          return false;
        }
      }
      return true;
    }
  } else {
    return false;
  }
}

export const googleAanalyticsPromoCode = (action, code) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      promoCode: code,
    },
  });
  console.log("GA4 NEW CODE CALLED", action);

  console.log("google analytics called ", action);
};
export const isoStringToNormalDate = function () {
  let tmp = new Date().toISOString().split("T")[0];
  tmp = tmp.split("-");
  return tmp[0] + "-" + tmp[1] + "-" + tmp[2];
};
export const purchaseIncident = async (resp, incrementId) => {
  let userAgent = navigator;
  let customerId = resp.customerId;
  let discountAmount = resp.discountAmount;
  let rs = null;
  try {
    rs = await detectIncognito();
  } catch (error) {
    console.log("error detect Incognito", error);
  }

  let auth = "";
  try {
    auth = authHeader();
    console.log("authHeader()", authHeader());
  } catch (error) {
    console.log("error in auth header get", error);
  }

  let currentdate = await isoStringToNormalDate();
  const obj = {
    title: "Purchase Incident",
    app_id: "crane",
    level: "info",
    description: "Purchase Incident",
    module: "storefront",
    tags: [],
    recorded_at: currentdate,
    organization_id: vueOrgId,
    metadata: {
      authHeader: auth,
      body: {
        purchase: {
          actionField: {
            id: incrementId,
            affiliation: "",
            revenue: resp.baseSubtotal,
            tax: resp.taxAmount,
            shipping: resp.shippingInclTax,
            coupon: resp.couponCode ? resp.couponCode : "",
            customerId: customerId,
            discountAmount: discountAmount,
            browserName: rs ? rs.browserName : "",
            isPrivate: rs ? rs.isPrivate : "",
          },
        },
      },
      error: "",
      url: "",
      status: "",
      platform: userAgent.platform,
      appVersion: userAgent.appVersion,
      appCodeName: userAgent.appCodeName,
    },
  };

  try {
    await doPostIncident(obj);
  } catch (error) {
    console.log("error in incident post", error);
  }

  console.log("purchaseIncident ", obj);
};
export const setDdRumUser = ({ basicInfo }) => {
  try {
    if (window) {
      window.DD_RUM.onReady(function () {
        //   let currentUser = window.DD_RUM.getUser()
        //if(currentUser && !currentUser.id){
        if (basicInfo.email) {
          window.DD_RUM.setUser({
            id: basicInfo.id,
            name: `${basicInfo.firstname} ${basicInfo.lastname}`,
            email: basicInfo.email,
            fingerprintId: basicInfo.fingerprintId
          })
        }
        else {
          window.DD_RUM.setUser({
            fingerprintId: basicInfo.fingerprintId
          })
        }

        //}
      })
    }
  }
  catch (error) {
    console.error("error in dd user set", error)
  }

}