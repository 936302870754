 import { wrapRequest } from './nav';


const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
const vueAppCraneOrderUrl = import.meta.env.VITE_CRANE_ORDER_URL;
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;
const zinreloKeyIndentifier= import.meta.env.VITE_ZINRELO_KEY_INDENTIFIER;
const zinreloPartnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;
const zinreloV2Api = import.meta.env.VITE_ZINRELO_V2_API;

 export const doFetchOrderProductsList = wrapRequest((emailId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
           
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/crorders?searchCriteria[filter_groups][0][filters][0][field]=customer_email&searchCriteria[filter_groups][0][filters][0][value]=${emailId}`, requestOptions)

});
export const doFetchOrderProduct = wrapRequest((orderId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
             
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/crorders/${orderId}`, requestOptions)

});

export const doFetchProductInvoice = async () => {
    let Invoice = {

        "base_currency_code": "string",
        "base_discount_amount": 0,
        "base_grand_total": 0,
        "base_discount_tax_compensation_amount": 0,
        "base_shipping_amount": 0,
        "base_shipping_discount_tax_compensation_amnt": 0,
        "base_shipping_incl_tax": 0,
        "base_shipping_tax_amount": 0,
        "base_subtotal": 0,
        "base_subtotal_incl_tax": 0,
        "base_tax_amount": 0,
        "base_total_refunded": 0,
        "base_to_global_rate": 0,
        "base_to_order_rate": 0,
        "billing_address_id": 0,
        "can_void_flag": 0,
        "created_at": "string",
        "discount_amount": 0,
        "discount_description": "string",
        "email_sent": 0,
        "entity_id": 0,
        "global_currency_code": "string",
        "grand_total": 32,
        "discount_tax_compensation_amount": 0,
        "increment_id": "string",
        "is_used_for_refund": 0,
        "order_currency_code": "string",
        "order_id": 0,
        "shipping_address_id": 0,
        "shipping_amount": 0,
        "shipping_discount_tax_compensation_amount": 0,
        "shipping_incl_tax": 0,
        "shipping_tax_amount": 0,
        "state": 0,
        "store_currency_code": "string",
        "store_id": 0,
        "store_to_base_rate": 0,
        "store_to_order_rate": 0,
        "subtotal": 32,
        "subtotal_incl_tax": 0,
        "tax_amount": 0,
        "total_qty": 1,
        "transaction_id": "string",
        "updated_at": "string",
        "items": [{
            "additional_data": "string",
            "base_cost": 0,
            "base_discount_amount": 0,
            "base_discount_tax_compensation_amount": 0,
            "base_price": 0,
            "base_price_incl_tax": 0,
            "base_row_total": 0,
            "base_row_total_incl_tax": 0,
            "base_tax_amount": 0,
            "description": "string",
            "discount_amount": 0,
            "entity_id": 0,
            "discount_tax_compensation_amount": 0,
            "name": "ENGRAVED SUN CARD",
            "parent_id": 0,
            "price": 32,
            "price_incl_tax": 0,
            "product_id": 0,
            "row_total": 32,
            "row_total_incl_tax": 0,
            "sku": "CC3700",
            "tax_amount": 0,
            "extension_attributes": {},
            "order_item_id": 0,
            "qty": 1
        }],
        "comments": [{
            "is_customer_notified": 0,
            "parent_id": 0,
            "extension_attributes": {},
            "comment": "string",
            "is_visible_on_front": 0,
            "created_at": "string",
            "entity_id": 0
        }],
        "extension_attributes": {
            "base_customer_balance_amount": 0,
            "customer_balance_amount": 0,
            "base_gift_cards_amount": 0,
            "gift_cards_amount": 0,
            "gw_base_price": "string",
            "gw_price": "string",
            "gw_items_base_price": "string",
            "gw_items_price": "string",
            "gw_card_base_price": "string",
            "gw_card_price": "string",
            "gw_base_tax_amount": "string",
            "gw_tax_amount": "string",
            "gw_items_base_tax_amount": "string",
            "gw_items_tax_amount": "string",
            "gw_card_base_tax_amount": "string",
            "gw_card_tax_amount": "string"
        }
    }
    return Invoice;
};

export const doFetchProductsList = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer 58rcjzlop08412gs0xa42q3qna21zxo2',
            'Access-Control-Allow-Origin': '*',

        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crproducts?searchCriteria[current_page]=1&searchCriteria[page_size]=27`, requestOptions)
});
export const doFetchProductsMedia = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer 58rcjzlop08412gs0xa42q3qna21zxo2',
            'Access-Control-Allow-Origin': '*',
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crproducts/${sku}?fields=sku,media_gallery_entries[file]`, requestOptions)
});
export const doFetchProductsAttributes = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer 58rcjzlop08412gs0xa42q3qna21zxo2',
            'Access-Control-Allow-Origin': '*',
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crproducts/${sku}?fields=sku,custom_attributes`, requestOptions)
});
export const doFetchAttributeList = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'

        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crproducts/attributes?searchCriteria=&fields=items[attribute_id,attribute_code,options,frontend_labels]`, requestOptions)
});

export const doFetchFilterOptions = async () => {
    let filterList = {
        "items": [{
            "attributeCode": "category",
            "options": [{
                "label": "Black",
                "value": "49"
            },
            {
                "label": "Blue",
                "value": "50"
            },
            {
                "label": "Brown",
                "value": "51"
            },
            {
                "label": "Gray",
                "value": "52"
            }
            ]
        },
        {
            "attributeCode": "personalized",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona&reg; performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "brand",
            "options": [{
                "label": "Normal",
                "value": "3"
            },
            {
                "label": "Cocona&reg; performance fabric",
                "value": "6"
            },
            {
                "label": "Canvas",
                "value": "8"
            },
            {
                "label": "Wool",
                "value": "8"
            }
            ]
        },
        {
            "attributeCode": "paper weight",
            "options": [{
                "label": "Normal",
                "value": "3"
            },
            {
                "label": "Cocona",
                "value": "6"
            },
            {
                "label": "Canvas",
                "value": "8"
            },
            {
                "label": "Wool",
                "value": "8"
            }
            ]
        },
        {
            "attributeCode": "product type/form",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "print method",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "paper color",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona&reg; performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "design style",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona&reg; performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "design color",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "theme",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "photo card",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        },
        {
            "attributeCode": "price",
            "options": [{
                "label": "Burlap",
                "value": "31"
            },
            {
                "label": "Cocona performance fabric",
                "value": "143"
            },
            {
                "label": "Canvas",
                "value": "32"
            },
            {
                "label": "Wool",
                "value": "144"
            }
            ]
        }
        ]
    }
    return filterList;
}
export const doFetchProductDetails = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V2/product/${sku}`, requestOptions)

});
export const doFetchConfiguredProductDetails = wrapRequest((productId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/crproducts?searchCriteria[filter_groups][0][filters][0][field]=entity_id&searchCriteria[filter_groups][0][filters][0][value]=${productId}?searchCriteria&fields=items[price,sku,media_gallery_entries,custom_attributes]`, requestOptions)

});
export const doFetchAttributeFilterProducts = wrapRequest(() => {
    
});

export const doFetchSearchProducts = wrapRequest(() => {
    
});
export const doFetchSortProducts = wrapRequest((pageNo, pageSize, sortBy, categoryIds, productNames, sideFilter) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    };
    let sortFlag = 'ASC';
    if (sortBy === 'relevance') {
        sortFlag = 'DESC';
    }
    let extraAttribute = "";
    if (pageSize != "ALL") {
        extraAttribute = `searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${pageNo}&`;
    }
    if (categoryIds) {
        
        let query = "";
        query = `?searchCriteria[filter_groups][0][filters][0][field]=entity_id&searchCriteria[filter_groups][0][filters][0][value]=${categoryIds}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=${sortFlag}`
        console.log('product', `${vueAppMagenotApiUrl}/rest/V1/crproducts/${query}`);
        return fetch(`${vueAppMagenotApiUrl}/rest/V1/crproducts/${query}`, requestOptions)

    } else {
        let nameSearchQuery = "";
        let filterQuery = "";
        let increment = 0;
        let query = "";
        if (productNames) {
            productNames.split(" ").forEach((name, index) => {
                nameSearchQuery += `searchCriteria[filter_groups][0][filters][${index}][field]=name&searchCriteria[filter_groups][0][filters][${index}][value]=%25${name}%25&searchCriteria[filter_groups][0][filters][${index}][condition_type]=like&`;
                increment++;
            });
        }

        if (sideFilter != 'e30=') {
            let filtertoNormal = JSON.parse(atob(sideFilter))
            Object.getOwnPropertyNames(filtertoNormal).forEach((key) => {
                filterQuery += `searchCriteria[filter_groups][${increment}][filters][0][field]=${key}&searchCriteria[filter_groups][${increment}][filters][0][value]=${filtertoNormal[key]}&`;
                increment++;
            })
        }


        query = `?${extraAttribute}${nameSearchQuery}${filterQuery}searchCriteria[filter_groups][${increment}][filters][0][field]=status&searchCriteria[filter_groups][${increment}][filters][0][value]=1&searchCriteria[filter_groups][${increment}][filters][0][condition_type]=eq&searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=DESC`;

        console.log('productNames', `${vueAppMagenotApiUrl}/rest/V1/crproducts/${query}`);
        return fetch(`${vueAppMagenotApiUrl}/rest/V1/crproducts/${query}`, requestOptions)
    }

});
export const doFetchCategoryList = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'

        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crcategories`, requestOptions)
});
 
export const dofetchCategoryListIdWise = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/crcategories/list?searchCriteria&fields=items[id,parent_id,name,custom_attributes]`, requestOptions)
});
export const doFetchCatelogProducts = wrapRequest((searchCriteria) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V2/suggestion?q=${searchCriteria}`, requestOptions);
});

export const doFetchRecentlyViewedList = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crproducts?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${sku}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[sortOrders][0][direction]=ASC`, requestOptions)
});
export const doSideBarFilter = wrapRequest((pageNo, pageSize, sortBy, categoryId, sideFilter, search) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    };

    let extraAttribute = "";
    if (pageSize != "ALL") {
        extraAttribute = `searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${pageNo - 1}&`;
    }

    let sortFlag = 'ASC';
    if (sortBy === 'relevance') {
        sortFlag = 'DESC';
    }

    if (categoryId) {
        let nameSearchQuery = "";
        if (sideFilter != 'e30=') {
            let filtertoNormal = JSON.parse(atob(sideFilter))
            Object.getOwnPropertyNames(filtertoNormal).forEach((key, index) => {
                nameSearchQuery += `searchCriteria[filter_groups][${index + 2}][filters][0][field]=${key}&searchCriteria[filter_groups][${index + 2}][filters][0][value]=${filtertoNormal[key]}&`;
            })
        }

        console.log('searchCat', `${vueAppMagenotApiUrl}/rest/V1/search?searchCriteria[filter_groups][0][filters][0][field]=category_ids&searchCriteria[filter_groups][0][filters][0][value]=${categoryId}&searchCriteria[filter_groups][1][filters][0][field]=visibility&searchCriteria[filter_groups][1][filters][0][value]=4&${nameSearchQuery}${extraAttribute}searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=${sortFlag}&searchCriteria[requestName]=catalog_view_container`);

        return fetch(`${vueAppMagenotApiUrl}/rest/V1/search?searchCriteria[filter_groups][0][filters][0][field]=category_ids&searchCriteria[filter_groups][0][filters][0][value]=${categoryId}&searchCriteria[filter_groups][1][filters][0][field]=visibility&searchCriteria[filter_groups][1][filters][0][value]=4&${nameSearchQuery}${extraAttribute}searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=${sortFlag}&searchCriteria[requestName]=catalog_view_container`, requestOptions);

    } else if (search) {
        let nameSearchQuery = "";
        let filterQuery = "";
        let increment = 0;
       
        if (search) {
            nameSearchQuery += `searchCriteria[filter_groups][0][filters][0][field]=search_term&searchCriteria[filter_groups][0][filters][0][value]=${search}&`;
            increment++;
        }
        if (sideFilter != 'e30=') {
            let filtertoNormal = JSON.parse(atob(sideFilter))
            Object.getOwnPropertyNames(filtertoNormal).forEach((key) => {
                filterQuery += `searchCriteria[filter_groups][${increment}][filters][0][field]=${key}&searchCriteria[filter_groups][${increment}][filters][0][value]=${filtertoNormal[key]}&`;
                increment++;
            })
        }
        console.log('search', `${vueAppMagenotApiUrl}/rest/V1/search?${nameSearchQuery}${filterQuery}searchCriteria[filter_groups][${increment}][filters][0][field]=visibility&searchCriteria[filter_groups][${increment}][filters][0][value]=4&${extraAttribute}searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=${sortFlag}&searchCriteria[requestName]=quick_search_container`);
        return fetch(`${vueAppMagenotApiUrl}/rest/V1/search?${nameSearchQuery}${filterQuery}searchCriteria[filter_groups][${increment}][filters][0][field]=visibility&searchCriteria[filter_groups][${increment}][filters][0][value]=4&${extraAttribute}searchCriteria[sortOrders][0][field]=${sortBy}&searchCriteria[sortOrders][0][direction]=${sortFlag}&searchCriteria[requestName]=quick_search_container`, requestOptions);
    }
});

export const doFetchPartPrice = wrapRequest((part) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(part)
    };
    return fetch(`${vueAppCraneOrderUrl}/pricer/price`, requestOptions)
});

export const doPlaceCraneOrder = wrapRequest((order) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(order)
    };
    return fetch(`${vueAppCraneOrderUrl}/Incoming/input`, requestOptions)
});
export const doPatchOne = wrapRequest((cms) => {
    let date = new Date().toISOString();
    cms.requested_at = date;
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(cms)
    };
    return fetch(`${imageLibApiUrl}/magento_sync?element=eq.${cms.element}`, requestOptions)
})

export const doFetchProductTags = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json', },
    };
    return fetch(`${imageLibApiUrl}/product?sku=eq.${sku}&select=tags,sku`, requestOptions);
})
export const doFetchRewardsPoints = wrapRequest((productInfo) => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
             'access-token': zinreloKeyIndentifier,
             'partner-id':zinreloPartnerId
        },
    };
    let encodeURIt=encodeURI(JSON.stringify((productInfo)));

    return fetch(`${zinreloV2Api}/loyalty-storefront/get-potential-points?product_list=`+encodeURIt
    , requestOptions)
});

