<template>
  <v-card class="d-inline-flex" elevation="0" max-width="850"  rounded="0">
    <v-container class="ml-10 mr-10 mt-3 mb-3 py-5">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="4"
                  class="mb-1"
                  v-for="(subcategorys, index) in subCategoryList.childrenData"
                  v-bind:key="index"
                  v-show="
                    subcategorys.isActive &&
                    subcategorys.childrenData.length > 0
                  "
                >
                  <p
                    @click="subCategory(subcategorys.id, subcategorys)"
                    :class="subcategorys.childrenData != '' ? '' : 'mb-1'"
                    class="cursor-pointer text-uppercase regular-font submenu-menu-heading pb-4 text-primary"
                  >
                    {{ subcategorys.name }}
                  </p>
                  <v-list
                    class="pa-0 ma-0"
                    v-show="subcategorys.childrenData != ''"
                  >
                    <v-list-item-group>
                      <v-list-item
                        class="pa-0"
                        v-for="(items, index) in subcategorys.childrenData"
                        v-bind:key="index"
                        v-show="items.isActive"
                      >
                        <v-list-item-title
                          class="body submenu-break-word pb-1 cursor-pointer"
                          :class="
                            items.id == subMenuItem && categoryId
                              ? 'active-sub-menu-link'
                              : 'submenu-break-word'
                          "
                          @click="
                            subCategoryProducts(
                              items.id,
                              subcategorys.id,
                              items
                            )
                          "
                          >{{ items.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col
                  cols="4"
                  class="py-0"
                  v-for="(subcategorys, index) in subCategoryList.childrenData"
                  v-bind:key="index"
                  v-show="
                    subcategorys.isActive && subcategorys.childrenData == ''
                  "
                >
                  <p
                    @click="subCategory(subcategorys.id, subcategorys)"
                    class="pb-0 cursor-pointer text-uppercase regular-font submenu-menu-heading pb-1 text-primary"
                  >
                    {{ subcategorys.name }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
           
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { googleAanalyticsEventCategory } from "@/services/util.service";
export default {
  name: "subMenu",
  props: ["subCategoryList", "mainMenu", "subMenuItem"],
  data: () => ({
    pageNo: 1,
    pageSize: 9,
    sortSelect: { label: "Position", value: "position" },
    search: "",
  }),
  computed: {
    categoryId() {
      return this.$route.meta.id;
    },
  },
  methods: {
    pageName() {
      return this.$route.name;
    },
    subCategory(subCategoryId, categoryDetails) {
     
      this.$emit("onActionScrollSub");
      googleAanalyticsEventCategory(
        "browse_category",
        "ecommerce",
        categoryDetails
      );
      this.$emit("action", {
        mainMenu: this.mainMenu,
        subMenu: subCategoryId,
        subMenuItem: subCategoryId,
      });
      this.$store.dispatch("catalogBrowser/search", {
          fullCatalog: window.craneCatalog,
          keywords: '',
          filters: [{label:'Category', prop:'ci', value: subCategoryId}],
          pageNumber: 1,
          pageSize: 9,
          sortBy: "position",
        }).catch(error => {
              error;
            });

    },
    subCategoryProducts(subCategoryIdItem, subCategoryId, categoryDetails) {
    
      this.$emit("onActionScrollSub");
      googleAanalyticsEventCategory(
        "browse_category",
        "ecommerce",
        categoryDetails
      );
      this.$emit("action", {
        mainMenu: this.mainMenu,
        subMenu: subCategoryId,
        subMenuItem: subCategoryIdItem,
      });
        this.$store.dispatch("catalogBrowser/search", {
          fullCatalog: window.craneCatalog,
          keywords: '',
          filters: [{label:'Category', prop:'ci', value: subCategoryIdItem}],
          pageNumber: 1,
          pageSize: 9,
          sortBy: "position",
        }).catch(error => {
              error;
            });
    },
  },
  created() {},
};
</script>
<style src="./SubMenu.scss" scoped  lang="scss" />