<template>
  <v-container class="py-0 my-0">
    <v-dialog
      v-model="confirmationDialogKey"
      persistent
      width="80%"
      height="auto" 
    >
      <v-card tile>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="11">
              <p class="body mt-7 mb-0 fontcolor-black-light text-wrap ">
                {{ confirmationDetails.confirmationMessage }}
              </p>
            </v-col>
            <v-col cols="1" class="text-right">
              <router-link
                to
                class="text-decoration-none black-text fontcolor-black-light"
              >
                <div @click="onAction(false)">X</div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4">
          <v-row no-gutters>
            <v-col cols="12">
              <v-hover v-slot="{ isHovering, props }">
                <v-btn
                  rounded="0"
                  class="text-uppercase body-font ma-2 button-background"
                  tile
                  size="large"
                  depressed
                  :variant="!isHovering ? 'flat' : 'outlined'"
                  :color="hover ? 'white' : 'primary'"
                  :class="hover ? 'primary-text' : 'white-text'"
                  @click="onAction(false)"
                  v-bind="props"
                  >CANCEL</v-btn
                >
              </v-hover>
              <v-hover v-slot="{ isHovering, props }">
                <v-btn
                  rounded="0"
                  class="text-uppercase body-font ma-2 button-background"
                  :variant="!isHovering ? 'flat' : 'outlined'"
                  :color="hover ? 'white' : 'error'"
                  :class="hover ? 'error--text' : 'white--text'"
                  tile
                  size="large"
                  depressed
                  v-bind="props"
                  @click="onAction(true)"
                  >OK</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: ["confirmationDialog", "confirmationDetails"],
data() {
    return {
       confirmationDialogKey:this.confirmationDialog
    }
},
  components: {},
  computed: {},
  methods: {
    onAction: function (action) {
      this.$emit("actionRemove", { submit: action });
    },
  },
  created() {},
};
</script>
