import { Base64 } from 'js-base64';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const spAuthName = import.meta.env.VITE_SP_AUTH_NAME?import.meta.env.VITE_SP_AUTH_NAME:'cr_cauth';
console.log("spAuthName",spAuthName)  
const getAuthUser = function() { 
    
    const userAuthEncoded = cookies.get(spAuthName);
    if (userAuthEncoded === null) {
        return null;
    }
    const userAuth = JSON.parse(Base64.decode(userAuthEncoded));
    return userAuth;
}
const getDomain = function(domain) {
    if(domain){
        const s = domain.split('.');
        if (s.length < 3) {
            return domain;
        }
        return s.slice(1).join('.');
    }
}
const setAuthUser = function(userAuth,flag) {
    if(flag)
    {
        const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
        cookies.set(spAuthName, userAuthEncoded, '180d', null, getDomain(window.location.hostname));
    }
    else
    {
        const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
        cookies.set(spAuthName, userAuthEncoded, '180d', null, getDomain(window.location.hostname));
    }
   
}
const resetAuthUser = function() {
    const au = getAuthUser();
    if (au === null) {
        return false;
    }
    cookies.remove(spAuthName, null, getDomain(window.location.hostname));
}

const authHeader = function() {
    const userAuth = getAuthUser();
    if (userAuth) {
        return { 'Authorization': 'Bearer ' + userAuth };
    } else {
        return {};
    }
}


const getCookieByName = function(name) {
    return cookies.get(name);
}

const setCookieByName = function(name, value) {
    cookies.set(name, value, '168h', null, getDomain(window.location.hostname))
}
const removeCookieByName = function(name) {
    cookies.remove(name, null, getDomain(window.location.hostname))
}
export { authHeader, getAuthUser, setAuthUser, resetAuthUser, getCookieByName, setCookieByName, removeCookieByName ,getDomain};