<template>
  <div>
    <v-row class="mt-12" justify="center" v-if="inProgress">

      <v-col
        v-for="(i, index) in 4"
        v-bind:key="index"
        lg="12"
        md="12"
        sm="12"
        cols="12"
        class="pr-lg-3 cursor-pointer"
      >
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-expansion-panels
      v-model="filterPanel"
      multiple
      class="filter-expansion-panels"
      v-else-if="filterList"
    >
      <v-expansion-panel
        tile
        class="transparent-background first-v-expansion-panel filter-expansion-panel"
        v-for="(attribute, index) in filterList"
        :key="index"
        elevation="0"
      >
        <v-expansion-panel-title
          class="fontsize-14 body ls-n09 color-1d text-uppercase px-3 py-0 my-n1 "
          :class="attribute.label == checkName(attribute.label ) ?'text-primary filter-panel-icon':'expansion-text-black'"
          v-if="attribute.counts.length"
          >{{ attribute.label  }}

        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <ul
            v-for="(option, index) in attribute.counts"
            :key="index"
            class="pl-0 filter-expansion-panel-list"
          >
            <li
              class="cursor-pointer filter-panel-subheading pb-1"
              :class="option.value == checkValue(option.value) ?'text-primary':'expansion-text-black'"
              v-if="option.value"
               @click="createFilter(
                 attribute.label,
                  attribute.prop,
                  option.value)"
            > {{ option.value}}({{option.count}})
            </li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: "FilterComponent",
  data: () => ({
      filterPanel: []
  }),
  props:["filterList","inProgress"],
  computed: {
    enabledFilters(){
      return this.$store.state.catalogBrowser.enabledFilters;
    },
     categoryId() {
      return this.$route.meta.id;
    },
  },
  watch: {
    enabledFilters(){
      this.showSelectedPanel();
    }
  },
  methods: {
    async createFilter(label, prop,value) {
      this.$emit("applyNewFilter",{label:label, prop:prop,value:value});
    },
    checkName(attribute) {
      let data = this.enabledFilters.filter(obj => obj.label === attribute);
      return data.length?data[0].label:"";
    },
    checkValue(attributeValue) {
      let data = this.enabledFilters.filter(obj => obj.value === attributeValue);
      return data.length?data[0].value:"";
    },
    showSelectedPanel() {
      this.filterPanel = [];
      if (this.enabledFilters.length > 0) {
        for (let i = 0; i < this.enabledFilters.length; i++) {
          this.filterList.filter(
           (x,index) => {
             if(x.label === this.enabledFilters[i].label)
              this.filterPanel.push(index);
           }
          );
        }
      }
    },
  },
  created(){
    this.showSelectedPanel();
  }
};
</script>
<style>
  .filter-expansion-panel .v-expansion-panel__shadow{
    border-radius: none !important;
    box-shadow:none !important;
  }
  .filter-expansion-panel.v-expansion-panel{
    position:unset;
  }
  .filter-expansion-panel.v-expansion-panel{
    margin-top  :0px !important
  }
  .filter-expansion-panels.v-expansion-panels{
    position:unset;
  }
.expansion-text-black .v-expansion-panel-title__overlay{
    background-color:#fff !important;
  }
  .filter-expansion-panels .v-expansion-panel-title__overlay{
    background-color:transparent !important;
  }
</style>
<style src="./FilterComponent.scss" lang="scss" scoped/>