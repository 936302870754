export const doFetchCategoryListNew = () => {
    return window.craneCatalog ? window.craneCatalog : {};
};

export const doFetchCmsList = () => {
    return window.craneCmsBlocks ? window.craneCmsBlocks : [];
};

export const doFetchProductList = () => {   
    return window.craneCatalog ? window.craneCatalog : {};
};

export const doFetchPageList = () => {
    return window.craneCmsPages ? window.craneCmsPages : [];
};