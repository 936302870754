 import { getDomain } from './auth';

import {  doPostIncident } from './incident.service';
import { authHeader } from './auth';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const loginUrl = import.meta.env.VITE_LOGIN_URL ? import.meta.env.VITE_LOGIN_URL : '/gate/sign-in';
const homeUrl = import.meta.env.VITE_ME_URL ? import.meta.env.VITE_ME_URL + '/account-dashboard' : '/';
const craneInterruptedPage = import.meta.env.VITE_CRANE_INTERRUPTED_PAGE_COOKIE ? import.meta.env.VITE_CRANE_INTERRUPTED_PAGE_COOKIE : '';
const vueMaintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE;
const vueOrgId = import.meta.env.VITE_APP_ORGANIZATION_ID;

const loginInterrupt = function(current_url, message) {

    sessionStorage[craneInterruptedPage] = current_url;
    sessionStorage.cinterrupt_message = message;
    window.location.href = loginUrl;
}
const isoStringToNormalDate = function () {

    let tmp = new Date().toISOString().split('T')[0];
    tmp= tmp.split("-") ;
    return  tmp[0] + "-" + tmp[1] + "-" + tmp[2];
}

const redirectToInterruptedPage = function() {

    const url = sessionStorage[craneInterruptedPage];
    const cInterruptedPage = cookies.get(craneInterruptedPage);
    if(cInterruptedPage){
        cookies.remove(craneInterruptedPage, null, getDomain(window.location.hostname));
        window.location.href = cInterruptedPage;
    }
    else if (url) {
        window.location.href = url;
        sessionStorage.removeItem(craneInterruptedPage);
    }

    else
    {
        window.location.href = homeUrl;
    }

}

const beforeEachRoute = (to, from, next) =>{
    const localcraneMaintenanceMode = localStorage.getItem(
        'craneMaintenanceModeFlag'
      )
    if (!to.matched.length) {
        location.href = to.path;
    }


    if (vueMaintenanceMode == "true" && to.name !== "Maintenance-New"&& (localcraneMaintenanceMode === null || localcraneMaintenanceMode === 'true')) {
        next({
            name: "Maintenance-New"
        })
    }
    try {
        let path = to.path;
        console.log("to path", to);
        if (path.includes("shop/category")) {
            let routeSplit = path.split("/");
            let newCategoryRoute = routeSplit.slice(3, routeSplit.length - 5).join("/");
            console.log("new route", newCategoryRoute);
            next({ path: "/" + newCategoryRoute });
        }
    } catch (error) {
        next({ path: "/" });
    }
    next();
}
const meWrapRequest = fn => (...params) =>
    fn(...params)
    .then(response => {
        if (!response.ok) {
            throw response;
        }
        return response.json();
    })
    .catch(error => meHandleError(error,...params));

const meHandleError = async (errorResponse,params) => {

    console.log('errorResponse',errorResponse)
    let userAgent = navigator;
    if (!errorResponse.ok) {
        let auth = "";
        let errorInTextMeNew="";
        try{
             errorInTextMeNew = await errorResponse.text();
        }
        catch(error){
            console.log("error in me errorResponse.text",error)
            errorInTextMeNew="error in me errorResponse.text"
        }

        try {
            auth = authHeader()
            console.log("authHeader()", authHeader())
        }
        catch (error) {
            console.log("error in auth header get", error)
        }

        let currentdate = await isoStringToNormalDate();
        const obj = {
            "title": 'Storefront Error ' + errorResponse.status,
            "app_id": 'crane',
            "level": 'minor',
            "description": 'Storefront Error ' + errorResponse.status,
            "module": 'storefront',
            "tags": [],
            "recorded_at": currentdate,
            "organization_id": vueOrgId,
            "metadata": {
                "authHeader": auth,
                "body": params,
                "error": errorInTextMeNew,
                "url": errorResponse.url,
                "status": errorResponse.status,
                "platform": userAgent.platform,
                "appVersion": userAgent.appVersion,
                "appCodeName": userAgent.appCodeName
            }
        };

        try{
            if(errorResponse.status && errorResponse.url)
            {
                await doPostIncident(obj);
            }
        }
        catch(error){
            console.log("error in me incident post",error)
        }

        switch (errorResponse.status) {
            case 401:
                {
                    if (localStorage.getItem("cr_changePassowrd") == "true") {
                        localStorage.clear("cr_changePassowrd")
                    } else {
                        let data_401="";
                        try{
                            let isLoginPage=(window.location.href.includes("gate/sign-in"));

                             data_401=  JSON.parse(errorInTextMeNew);
                             if(data_401.message&&data_401.message.includes("to access %resources")&&!isLoginPage){
                                loginInterrupt(window.location.href, 'There was an access violation caused by your request. You need to sign back in please.');
                             }
                        }
                        catch(error){
                            console.log("error in data_401 parsing",error)
                            data_401={message:errorInTextMeNew}
                        }

                    }
                    break;
                }
            case 400:
                {
                    let text_400 = errorInTextMeNew;
                    let data_400 ="";
                    try{
                        data_400=  JSON.parse(text_400);
                        if(data_400 && data_400.message && data_400.message.includes('Authorize.Net CIM Gateway'))
                        {
                            data_400={message:'Payment processing error. Please try a different credit card OR contact your bank for more information on why we cannot process this transaction with this card.'}
                        }
                    }
                    catch(error){
                        console.log("error in text_400 parsing",error)
                        data_400={message:text_400}
                    }

                    throw data_400;
                }
            case 500:
                {
                    let text_500 = errorInTextMeNew;
                    let data_500 ="";
                    try{
                        data_500=  JSON.parse(text_500);
                        if(data_500 && data_500.message && data_500.message.includes('Authorize.Net CIM Gateway'))
                        {
                            data_500={message:'Payment processing error. Please try a different credit card OR contact your bank for more information on why we cannot process this transaction with this card.'}
                        }
                        else  if(data_500 && data_500.message && data_500.message.includes('Internal Error'))
                        {
                            data_500={message:'A cloud communication error has occurred. Please wait and try again.'}
                        }
                    }
                    catch(error){
                        console.log("error in text_500 parsing",error)
                        data_500={message:text_500}
                    }

                    throw data_500;
                }

            default:
                let error_text = errorInTextMeNew;
                let error_data  ="";
                try{
                    error_data=  JSON.parse(error_text);
                }
                catch(error){
                    console.log("error in me default text parsing",error)
                    error_data={message:error_text}
                }

                throw error_data;

        }
    }
};

const wrapRequest = fn => (...params) =>
    fn(...params)
    .then(response => {
        if (!response.ok) {
            throw response;
        }
        return response.json();
    })
    .catch(error => handleError(error,...params));

const handleError = async   (errorResponse,params) => {

    console.log('errorResponse',errorResponse)
    let userAgent = navigator;
    if (!errorResponse.ok) {

        let errorInTextNew="";
        try{
            errorInTextNew = await errorResponse.text();
        }
        catch(error){
            console.log("error in errorResponse.text",error)
            errorInTextNew="error in errorResponse.text"
        }

        let auth = ""
        try {
            auth = authHeader()
            console.log("authHeader()", authHeader())
        }
        catch (error) {
            console.log("error in auth header get", error)
        }

        let currentdate = await isoStringToNormalDate();
        const obj = {
            "title": 'Storefront Error ' + errorResponse.status,
            "app_id": 'crane',
            "level": 'minor',
            "description": 'Storefront Error ' + errorResponse.status,
            "module": 'storefront',
            "tags": [],
            "recorded_at": currentdate,
            "organization_id": vueOrgId,
            "metadata": {
                "authHeader": auth,
                "body": params,
                "error": errorInTextNew,
                "url": errorResponse.url,
                "status": errorResponse.status,
                "platform": userAgent.platform,
                "appVersion": userAgent.appVersion,
                "appCodeName": userAgent.appCodeName
            }
        };

        try{
            if(errorResponse.status && errorResponse.url)
            {
                await doPostIncident(obj);
            }

        }
        catch(error){
            console.log("error in incident post",error)
        }

        switch (errorResponse.status) {
            case 403:
                {
                    let text_403 = errorInTextNew;
                    let data_403 ="";

                    try{
                        data_403=  JSON.parse(text_403);
                    }
                    catch(error){
                        console.log("error in text_403 parsing",error)
                        data_403={message:text_403}
                    }

                    throw data_403;

                }

            case 401:
                {
                    let text_401 = errorInTextNew;
                    let data_401 ="";

                    try{
                        data_401=  JSON.parse(text_401);
                    }
                    catch(error){
                        console.log("error in text_401 parsing",error)
                        data_401={message:text_401}
                    }

                    throw data_401;

                    break;
                }
            case 400:
                {
                    let text_400 = errorInTextNew;
                    let data_400 ="";

                    try{
                        data_400=  JSON.parse(text_400);
                    }
                    catch(error){
                        console.log("error in text_400 parsing",error)
                        data_400={message:text_400}
                    }

                    if (data_400.code == "22023" && data_400.message.indexOf('role') >= 0 &&
                        data_400.message.indexOf('does not exist')) {
                        loginInterrupt(window.location.href, 'There was an access violation caused by your request. You need to sign back in please.');
                    } else {

                        throw data_400;
                    }
                    break;
                }
            case 404:
                {
                    let text_404 = errorInTextNew;
                    let data_404 ="";

                    try{
                        data_404=  JSON.parse(text_404);
                    }
                    catch(error){
                        console.log("error in text_404 parsing",error)
                        data_404={message:text_404}
                    }

                    throw data_404;
                    break;
                }
            case 500:
                {
                    let text_500 = errorInTextNew;
                    let data_500 ="";

                    try{
                        data_500=  JSON.parse(text_500);
                         if(data_500 && data_500.message && data_500.message.includes('Internal Error'))
                        {
                            data_500={message:'A cloud communication error has occurred. Please wait and try again.'}
                        }
                    }
                    catch(error){
                        console.log("error in text_500 parsing",error)
                        data_500={message:text_500}
                    }

                    if (data_500.code == "22023" && data_500.message.indexOf('role') >= 0 &&
                        data_500.message.indexOf('does not exist')) {
                        loginInterrupt(window.location.href, 'There was an access violation caused by your request. You need to sign back in please.');
                    } else {
                        throw data_500;
                    }
                    break;
                }
            default:

                throw { message: 'Unexpected error while communicating with the cloud' };

        }
    }
};
export {
    loginInterrupt,
    redirectToInterruptedPage,
    beforeEachRoute,
    wrapRequest,
    handleError,
    meWrapRequest
};