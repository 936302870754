import { wrapRequest } from './nav';
const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;
const retailorId = import.meta.env.VITE_RETAILOR_ID;

export const doPostCartItem = wrapRequest((itemNumber, quantity, ccid, price, personalizeDetails, quoteId) => {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "cartItem": {
                "quote_id": quoteId,
                "sku": itemNumber,
                "qty": quantity
            },
            "ccid": ccid,
            "order_total": price,
            "personalize_Data": personalizeDetails
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`, requestOptions)
});
export const doPostConfiguredCartItem = wrapRequest((itemNumber, quantity, typeId, optionId, optionValue, quoteId) => {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "cartItem": {
                "quote_id": quoteId,
                "sku": itemNumber,
                "qty": quantity,
                "product_type": typeId,
                "product_option": {
                    "extension_attributes": {
                        "configurable_item_options": [{
                            "option_id": optionId,
                            "option_value": optionValue
                        }]
                    }
                }
            },
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`, requestOptions)
});
export const doPutConfiguredCartItem = wrapRequest((sku, qty, quoteId, itemId, optionId, optionValue) => {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "cartItem": {
                "quote_id": quoteId,
                "sku": sku,
                "qty": qty,
                "item_id": itemId,
                "product_option": {
                    "extension_attributes": {
                        "configurable_item_options": [{
                            "option_id": optionId,
                            "option_value": optionValue
                        }]
                    }
                }
            },
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${itemId}`, requestOptions)
});
export const doPostGiftCartItem = wrapRequest((data, quoteId, giftCartDetailsGuest) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            "cartItem": {
                "sku": data.sku,
                "qty": data.qty,
                "quoteId": quoteId,
                "product_option": {
                    "extension_attributes": {
                        "giftcard_item_option": {
                            "giftcard_sender_name": giftCartDetailsGuest.senderNameGuest,
                            "giftcard_sender_email": giftCartDetailsGuest.senderEmailGuest,
                            "giftcard_recipient_name": giftCartDetailsGuest.recipientNameGuest,
                            "giftcard_recipient_email": giftCartDetailsGuest.recipientEmailGuest,
                            "giftcard_message": giftCartDetailsGuest.senderMessageGuest,
                            "giftcard_amount": giftCartDetailsGuest.giftCardAmtGuest
                        }
                    }
                }
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`, requestOptions)
});
export const doPutGiftCartItem = wrapRequest((data, quoteId, giftCartDetailsGuest) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            "cartItem": {
                "sku": data.sku,
                "qty": data.qty,
                "quote_id": quoteId,
                "item_id": data.itemId,
                "product_option": {
                    "extension_attributes": {
                        "giftcard_item_option": {
                            "giftcard_sender_name": giftCartDetailsGuest.senderNameGuest,
                            "giftcard_sender_email": giftCartDetailsGuest.senderEmailGuest,
                            "giftcard_recipient_name": giftCartDetailsGuest.recipientNameGuest,
                            "giftcard_recipient_email": giftCartDetailsGuest.recipientEmailGuest,
                            "giftcard_message": giftCartDetailsGuest.senderMessageGuest,
                            "giftcard_amount": giftCartDetailsGuest.giftCardAmtGuest
                        }
                    }
                }
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${data.itemId}`, requestOptions)
});
export const doFetchQuote = wrapRequest(() => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts`, requestOptions)
});
export const doFetchCartId = wrapRequest((quoteId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}`, requestOptions)
});
export const doFetchCartProducts = wrapRequest((guestCartId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/cart/data?cartid=${guestCartId}`, requestOptions)
});
export const doFetchTotalAmt = wrapRequest((quoteId) => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/totals`, requestOptions)
});
export const doDeleteCartProduct = wrapRequest((productId, quoteId) => {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${productId}`, requestOptions)
});
export const doPutCartItem = wrapRequest((sku, qty, quoteId, itemId, ccid, price, personalizeDetails) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "cartItem": {
                "quote_id": quoteId,
                "sku": sku,
                "qty": qty,
                "item_id": itemId,
            },
            "ccid": ccid,
            "order_total": price,
            "personalize_Data": personalizeDetails
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${sku}`, requestOptions)
});
export const doFetchCoupon = wrapRequest((quoteId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons`, requestOptions)
});
export const doApplyDiscountCode = wrapRequest((code, quoteId) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons/${code}`, requestOptions)
});
export const doRemoveDiscountCode = wrapRequest((quoteId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons`, requestOptions)
});
export const doFetchGiftMessage = wrapRequest((quoteId, itemId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/item/data?itemId=${itemId}&cartid=${quoteId}`, requestOptions)
});
export const doPutCConectionCartItem = wrapRequest((sku, qty, ccid) => {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${vueAppNodeslsApiUrl}/cc-update-cart-item-quantity?retailerID=${retailorId}&orderNumber=${ccid}&itemNumber=${sku}&quantity=${qty}`, requestOptions)
});